<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear">
        <div class="header_logo clear">
          <a class="header_logo1 fl" href="/"><img src="./images/logo.png" alt=""/></a>
          <p class="header_yy pcNone fl" style="display:none"><a href="/">中</a>|<a href="/en/">EN</a></p>
          <a href="javascript:void(0)" class="headerLoadMore pcNone fl"></a>
          <a href="javascript:void(0)" class="headerCloseMore pcNone fl"></a>
          <form name="searchform" class="isdc_xiangyou" style="float:right;    margin-right: 0.2rem;" action="/search/"
                method="get" onsubmit="return checkForm1()">
            <p class="header_top_mes mobileNone"><a class="qy_email" href="http://mail.crtamg.com.cn"
                                                    target="_blank">企业邮箱</a>
              <!-- <a href="/">中</a>|<a href="/en/">EN</a> -->
            </p>
            <div class="searchBox fr">
              <a href="javascript:void(0)"></a>
              <div data-open="false" style="display: block;">
                <input class="search_txt flx" type="text" name="searchword" placeholder="请输入搜索关键词" id="searchword"
                       autocomplete="off"/>
                <input class="search_btn fr" value="" type="submit"/>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="rt on"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a href="#/gywm/">关于我们</a>
              <div class="gx_headNav_menu">
                <a href="#/gywm/">单位简介</a>
                <a href="src/views/gywm/gltd/">机构设置</a>
                <a href="src/views/gywm/zzjg/">资质荣誉</a>
              </div>
            </li>
            <li class="nav-link">
              <a href="#/allXwzx">新闻咨询</a>
            </li>
            <li class="rt3">
              <a href="#/kjcx">科技创新</a>
            </li>
            <li class="rt3">
              <a href="#/ywly">业务领域</a>
            </li>
            <li class="rt4">
              <a href="#/allDdzx">党群建设</a>
              <div class="gx_headNav_menu">
                <a href="./ddjs/djdt/">主题教育</a>
                <a href="./ddjs/djzt/">党群建设</a>
              </div>
            </li>
            <li class="rt5">
              <a href="#/fwzc">服务支持</a>
            </li>

            <li class="rt8">
              <a href="#/lxwm/">联系我们</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->
    <div class="ejAbtrtC ejXwzxxq">
      <!--      <img class="mainBan" src="./images/P020220729544785606008.png" alt=""/>-->
      <div class="ejCon clear">
        <div class="ejL">
          <div class="artT">
            法律法规
          </div>
          <ul>
            <li class="on">
              <a title='最新法规'>
                最新法规
              </a>
            </li>
          </ul>
        </div>
        <div class="ejR">
          <div class="ejRt clear">
            <div class="ejRtT">法律法规</div>
            <div class="ejcub">
              <span>当前位置：</span> <a href="../../../" target="_self" title="首页" class="CurrChnlCls">首页</a>&nbsp;>&nbsp;<a
                href="#/allXwzx" target="_self" title="法律法规" class="CurrChnlCls">法律法规</a>&nbsp;>
            </div>
          </div>
          <div class="ejRb clear">
            <ul>
              <li class="AllLi" v-for="item in userList"><a class="AllA" @click="$router.push({path:'/jtdt/'+item.id})"
                                                            target="_blank"
                                                            >{{
                  item.articleTitle
                }}</a>
              </li>
            </ul>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="100"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- footer start -->
    <div class="rt_footer">
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
            <li class="yqlj"><a href="">友情链接</a></li>
            <li><a href="">自然资源部</a></li>
            <li><a href="">国家基础地理信息中心</a></li>
            <li><a href="">四川省自然资源厅</a></li>
            <li><a href="">四川省测绘地理信息局</a></li>
          </ul>
        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="./wzdt/" target="_blank">网站地图</a>|
              <a href="./lxwm/" target="_blank">联系我们</a>|

            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省煤田测绘工程院版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh"></a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
// import axios from "axios";

export default {
  components: {},
  name: "AllXwzx",
  data() {
    return {
      userList: [],
      total: "",
      currentpage: 1,
      pageSize: 10,
    };
  },
  methods: {
    checkForm1() {
      $("#searchword").val($.trim($("#searchword").val()));
      $("#searchword").val($("#searchword").val().replace(/请输入搜索内容/g, ''));
      $("#searchword").val($("#searchword").val().replace(/[\-\_\,\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g, ''));
      $("#searchword").val($("#searchword").val().replace(/script/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/iframe/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/update/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/alert/ig, ''));

      if ($("#searchword").val() == "") {
        alert("请输入搜索内容");
        return false;
      } else {
        return true;
      }
    },
    handleSizeChange(pageSize) {
      console.log("页面大小改变" + pageSize)
      this.pageSize = pageSize
      console.log(this.pageSize)
      this.pagehelper()
    },
    handleCurrentChange(pageNum) {
      console.log("当前页面改变" + pageNum)
      this.currentpage = pageNum
      console.log(this.currentpage)
      this.pagehelper()
    },
    pagehelper() {
      var articleItem = {
        pageNum: this.currentpage,
        pageSize: this.pageSize,
        articleType: 'notic'
      }
      var jsonText = JSON.stringify(articleItem);

      this.axios.post("/article/getByPaging", jsonText, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => {
        console.log(res)
        this.userList = res.data.data.records;
        this.currentpage = res.data.data.pages;
        this.pageSize = res.data.data.size;
        this.total = res.data.data.total;
      })
    }
  },
  mounted() {
    this.pagehelper()
  },
  created() {
  }
}
</script>

<style scoped>
.AllA {
  display: block;
  float: left;
  font-size: 16.5px;
  color: #333;
  padding-left: 15px;
  width: 560px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
    background: url("../../public/static/images/rt_indexList_d_v2020.png") no-repeat left center;
}

.AllLi {
  overflow: hidden;
  padding: 0 20px 0 10px;
  border-bottom: 1px dashed #d1d1d1;
  height: 48px;
  line-height: 48px;
}

.el-pagination {
  position: absolute;
  margin: 20px;
  white-space: nowrap;
  padding: 0px;
  color: #303133;
  font-weight: 700;
  left: 700px;
}

.video {
  width: 80%;
  height: auto;
  margin: 0 auto;

}

.video .video_box {
  width: 100%;
  height: auto;
  background: #000;
}
</style>
