import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import gywmView from '../views/gywmView.vue'
import XwzxForView from '../views/XwzxForView.vue'
import XwzxView from '../views/XwzxView.vue'
import YwlyView from "../views/YwlyView";
import KjcxView from "../views/KjcxView";
import DdjsView from "../views/DdjsView";
import FwzcView from "../views/FwzcView";
import LxwmView from "../views/LxwmView";
import SearchView from "../views/SearchView";
import kjcxMain from "../views/kjcxMain";
import AllXwzx from "../views/AllXwzx";
import AllDdjs from "../views/AllDdjs";
import DdjsMainView from "../views/DdjsMainView";
import AllDdjsEducation from "../views/AllDdjsEducation";
import Allhydt from "../views/Allhydt";
import Allflfg from "../views/Allflfg";
import gywmJgszView from "../views/gywmJgszView";
import KeyProjects from "../views/KeyProjects";
import HomeCarousel from "../views/HomeCarousel";
import honorView from "../views/honorView";
import gywmXsdwView from "@/views/gywmXsdwView";
import AllZyxx from "@/views/AllZyxx";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    // {
    //     path: '/xwzx',
    //     name: 'xwzx',
    //     component: XwzxView,
    // },
    {
        path: '/jtdt',
        name: 'jtdt',
        component: XwzxForView,
    },
    {
        path: '/gywm',
        name: 'gywm',
        component: gywmView,
    },
    {
        path: '/allZyxx',
        name: 'zyxx',
        component: AllZyxx,
    },
    {
        path: '/ywly',
        name: 'ywly',
        component: YwlyView,
    },
    {
        path: '/kjcx',
        name: 'kjcx',
        component: KjcxView,
    },
    // {
    //     path: '/ddjs',
    //     name: 'ddjs',
    //     component: DdjsView,
    // },
    {
        path: '/fwzc',
        name: 'fwzc',
        component: FwzcView,
    },
    {
        path: '/lxwm',
        name: 'lxwm',
        component: LxwmView,
    },
    // {
    //     path: '/search',
    //     name: 'search',
    //     component: SearchView,
    // },
    {
        path: '/kjcxMain',
        name: 'kjcxMain',
        component: kjcxMain,
    },
    {
        path: '/zdgc',
        name: 'zdgc',
        component: KeyProjects,
    },
    {
        path: '/allXwzx',
        name: 'allXwzx',
        component: AllXwzx,
    },
    {
        path: '/allDdzx',
        name: 'allDdzx',
        component: AllDdjs,
    },
    {
        path: '/ddjsMain',
        name: 'ddjsMain',
        component: DdjsMainView,
    },
    {
        path: '/AllDdjsEducation',
        name: 'AllDdjsEducation',
        component: AllDdjsEducation,
    },
    {
        path: '/allhydt',
        name: 'allhydt',
        component: Allhydt,
    },
    // {
    //     path: '/allflfg',
    //     name: 'allflfg',
    //     component: Allflfg,
    // },
    {
        path: '/jgsz',
        name: 'jgsz',
        component: gywmJgszView,
    },
    {
        path: '/xsdw',
        name: 'xsdw',
        component: gywmXsdwView,
    },

    {
        path: '/lbt',
        name: 'lbt',
        component: HomeCarousel,
    },
    {
        path: '/zzry',
        name: 'zzry',
        component: honorView,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router
