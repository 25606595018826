<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear" id="topClass">
        <!--        <p id="pTitle"></p>-->
        <a href="/"><img style="margin-top: 50px;" src="/static/zzry/top2.0.png" alt=""/></a>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="nav-link"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a class="nav-link" href="/gywm">关于我们</a>
              <div class="gx_headNav_menu">
                <a class="nav-link-a" href="/gywm">单位简介</a>
                <a class="nav-link-a" href="/jgsz">机构设置</a>
                <a class="nav-link-a" href="/zzry">资质荣誉</a>
              </div>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/xsdw">部门介绍</a>
            </li>
            <li class="nav-link">
              <a class="nav-link" href="/allXwzx">新闻资讯</a>
            </li>
            <li class="rt3">
              <a class="nav-link" style="background-color: #0056a2" href="/kjcx">科技创新</a>
<!--              <div class="gx_headNav_menu">-->
<!--                <a class="nav-link-a" href="/kjcx">创新平台</a>-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/allDdzx">设备展示</a>&ndash;&gt;-->
<!--              </div>-->
            </li>

            <li class="rt4">
              <a class="nav-link" href="/allDdzx">党群建设</a>
<!--              <div class="gx_headNav_menu">-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/AllDdjsEducation">主题教育</a>&ndash;&gt;-->
<!--                <a class="nav-link-a" href="/allDdzx">党群建设</a>-->
<!--              </div>-->
            </li>
            <!--            <li class="rt5">-->
            <!--              <a class="nav-link" href="#/fwzc">服务支持</a>-->
            <!--            </li>-->

<!--            <li class="rt6">-->
<!--              <a class="nav-link" href="/ywly">主营业务</a>-->
<!--            </li>-->

          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->

    <div class="ejAbtrtC ejXwzxxq">
      <div class="ejCon clear">
        <div class="ejL">
          <div class="artT">
            科技创新
          </div>
          <ul>
            <li class="on">
              <a  href="../">
                {{type}}
              </a>
            </li>
          </ul>
        </div>
        <div class="ejR">
          <div class="ejRt clear">
            <div class="ejRtT">{{type}}</div>
            <div class="ejcub">
              <span>当前位置：</span> <a href="./" target="_self" title="首页" class="CurrChnlCls">首页</a>&nbsp;>&nbsp;<a
                href="/kjcx" target="_self" title="科技创新" class="CurrChnlCls">科技创新</a>&nbsp;>&nbsp;<a href="/kjcx"
                                                                                                      target="_self"

                                                                                                      class="CurrChnlCls">{{type}}</a>
            </div>
          </div>
          <div class="ejRb clear">
            <div class="title">
              {{ pictureList.articleTitle }}
            </div>
            <div class="sectitle">

            </div>
<!--            <div class="infos clear">-->
<!--              <span class="source">作者：{{ pictureList.articleAuthor }}</span>-->
<!--              <span class="date">日期：{{ pictureList.articlePublishTime }}</span>-->
<!--            </div>-->
            <div class="ejartD" v-html="pictureList.articleContent">

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer start -->
    <div class="rt_footer" style="position: relative">
      <img style="position: absolute;top: -11px;left: 450px" src="../assets/import/友情链接.png"/>
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
<!--            <li class="yqlj"><a href="">友情链接</a></li>-->
            <li><a href="http://scdzdc.cn/" target="_blank">四川省地质调查研究院</a></li>
            <li><a href="https://www.mnr.gov.cn/" target="_blank">自然资源部</a></li>
            <li><a href="https://ngcc.cn/ngcc/" target="_blank">国家基础地理信息中心</a></li>
            <li><a href="http://dnr.sc.gov.cn/" target="_blank">四川省自然资源厅</a></li>
            <li><a href="http://scsm.mnr.gov.cn/" target="_blank">四川省测绘地理信息局</a></li>
            <li><a class="fwzc" href="/fwzc" target="_blank">服务支持</a></li>
          </ul>

        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="/lxwm" target="_blank">网站地图</a>|
              <a href="/lxwm" target="_blank">联系我们</a>|
              <!--              -->
            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省地质调查研究院测绘地理信息中心版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn/?lang=zh-cn#/Integrated/index" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh">川公网安备 51010502010510号 </a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
// import axios from "axios";

export default {
  components: {},
  name: "KjcxMain",
  data() {
    return {
      pictureList: [],
      type: "",
      parentType: "",
    };
  },
  methods: {
    checkForm1() {
      $("#searchword").val($.trim($("#searchword").val()));
      $("#searchword").val($("#searchword").val().replace(/请输入搜索内容/g, ''));
      $("#searchword").val($("#searchword").val().replace(/[\-\_\,\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g, ''));
      $("#searchword").val($("#searchword").val().replace(/script/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/iframe/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/update/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/alert/ig, ''));

      if ($("#searchword").val() == "") {
        alert("请输入搜索内容");
        return false;
      } else {
        return true;
      }
    },
  },
  mounted(){
    let id = this.$route.query.id
    this.axios.get("/in/getOneById?id=" + id).then(res => {
      console.log(res)
      this.pictureList = res.data.data;
      switch (res.data.data.articleType) {
        case "cxpt":
          this.type = "创新平台"
          break;
        case "notic":
          this.type = "法律法规"
          break;
      }
    }).catch(err => {
      console.log(err)
    })
    console.log(this.$route.params);
  }
}
</script>

<style>
.video {
  width: 80%;
  height: auto;
  margin: 0 auto;

}

.video .video_box {
  width: 100%;
  height: auto;
  background: #000;
}

.nav-link {
  display: block;
  width: 170px;
  text-align: center;

}

.nav-link:hover {
  background-color: #0056a2 !important;
}

.nav-link-a {
  display: block;
  width: 186px;
}

.nav-link-a:hover {
  background-color: rgba(21, 152, 255, 0.5) !important;
  border-radius: 4px;
}

#topClass {
  background-image: url('@/assets/top.jpg');
  background-size: 100% 100%;
  width: 100%;
  text-align: center;
  display: table;
  height: 224px;
}

#topClass a {
  display: table-cell;
  vertical-align: middle;
}

.fwzc {
  color: #0057fb;
  font-weight: bold;
  font-size: 20px;
}

/** 添加分割线*/
.fwzc:before {
  content: "|";
  float: left;
  margin-right: 20px;
  color: #94959E;
}

.fwzc:after {
  content: "|";
  float: right;
  margin-left: 20px;
  color: #94959E;
}
</style>
