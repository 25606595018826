import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import BaiduMap from 'vue-baidu-map'
const instance = axios.create({
  baseURL: '/api', // 根据实际情况修改API地址
  timeout: 5000 // 设置超时时间，单位为ms
});
Vue.use( BaiduMap, { ak : 'eVNYZEBKI97NtTmx2yUTD5BMoHi0mzLR' } )
Vue.prototype.axios = instance;
Vue.use(ElementUI);
Vue.config.productionTip = false
console.log(axios,111);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
