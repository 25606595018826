<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear" id="topClass">
        <!--        <p id="pTitle"></p>-->
        <a href="/"><img style="margin-top: 50px;" src="/static/zzry/top2.0.png" alt=""/></a>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="nav-link" style="background-color: #0056a2"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a class="nav-link" href="/gywm">关于我们</a>
              <div class="gx_headNav_menu">
                <a class="nav-link-a" href="/gywm">单位简介</a>
                <a class="nav-link-a" href="/jgsz">机构设置</a>
                <a class="nav-link-a" href="/zzry">资质荣誉</a>
              </div>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/xsdw">部门介绍</a>
            </li>
            <li class="nav-link">
              <a class="nav-link" href="/allXwzx">新闻资讯</a>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/kjcx">科技创新</a>
<!--              <div class="gx_headNav_menu">-->
<!--                <a class="nav-link-a" href="/kjcx">创新平台</a>-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/allDdzx">设备展示</a>&ndash;&gt;-->
<!--              </div>-->
            </li>

            <li class="rt4">
              <a class="nav-link" href="/allDdzx">党群建设</a>
<!--              <div class="gx_headNav_menu">-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/AllDdjsEducation">主题教育</a>&ndash;&gt;-->
<!--                <a class="nav-link-a" href="/allDdzx">党群建设</a>-->
<!--              </div>-->
            </li>
            <!--            <li class="rt5">-->
            <!--              <a class="nav-link" href="#/fwzc">服务支持</a>-->
            <!--            </li>-->

<!--            <li class="rt6">-->
<!--              <a class="nav-link" href="/ywly">主营业务</a>-->
<!--            </li>-->

          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->

    <div class="rt_maincont clear">
      <div class="mainContMes">
        <a class="title mobileNone topA" :href="'/jtdt?id='+this.NewsListOne.id">{{ this.NewsListOne.articleTitle }}</a>
      </div>

      <!--      <li class="swiper-slide" v-for="item in HomeCarouselList">-->
      <!--        <a @click="$router.push({path:'/lbt/'+item.id})" target="_blank">-->
      <!--          <img :src="'http://'+item.picUrl" alt=""/>-->
      <!--          <p>{{ item.title }}</p>-->
      <!--        </a>-->
      <!--      </li>-->
      <div class="mainCont_lb swiper-container fl">
        <div class="ellbts" style="height: 330px">
          <el-carousel height="330px" arrow="always">
            <el-carousel-item v-for="item in HomeCarouselList" :key="item.id" style="height: 320px">
              <h3 style="height: 100%" class="medium"><a :href="'/lbt?id='+item.id"><img
                  style="width: 100%;height: 100%"
                  :src="item.articlePicUrl" alt=""/>
              </a></h3>
              <span
                  style="z-index: 999;position: absolute;bottom: 0px;background-color: rgba(0,0,0,0.6);width: 100%;height:30px;line-height: 30px;color: #fff;padding-left: 10px">{{
                  item.articleTitle
                }}</span>
            </el-carousel-item>
          </el-carousel>

        </div>
      </div>

      <div class="main_ListBox fr">
        <div class="listTitleTop">
          <el-tabs v-model="activeName" style="margin-top: -5px">
            <el-tab-pane label="最新要闻" name="first">
              <ul class="rt_list">
                <li class="liA" v-for="item in NewsList">
                  <a class="AllLi" :href="'/jtdt?id='+item.id" target="_blank">
                    <p class="PStyle">{{ item.articleTitle }}</p>
                    <span class="spanStyle">{{ item.articlePublishTime }}</span>
                  </a>
                </li>
              </ul>
            </el-tab-pane>

            <el-tab-pane label="基层动态" name="second">
              <ul class="rt_list">
                <li class="liA" v-for="item in IndustryList"><a class="AllLi" :href="'/jtdt?id='+item.id" target="_blank">
                  <p class="PStyle">{{ item.articleTitle }}</p>
                  <span class="spanStyle">{{ item.articlePublishTime }}</span>
                </a>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="公示公告" name="third">
              <ul class="rt_list">
                <li class="liA" v-for="item in getAnnouncementList"><a class="AllLi" :href="'/jtdt?id='+item.id" target="_blank">
                  <p class="PStyle">{{ item.articleTitle }}</p>
                  <span class="spanStyle">{{ item.articlePublishTime }}</span>
                </a>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
          <a style='position: absolute;right:10px;top:80px;color:#999999' href="/allXwzx">
            <i
                style="float: right;line-height: 23px;margin-right: 20px;color: #999999;font-weight: bold"
                class="el-icon-arrow-right"></i>查看更多
          </a>
          <!--          <h3><a style="color:#b81c22" href="./xwzx/jtdt/" target="_blank">新闻咨询</a></h3>-->
        </div>
      </div>

      <div class="mainCont_tab fl clear">
        <div class="listTitleCenter">
          <h3><a href="/allZyxx">重要信息专栏<i
              style="float: right;line-height: 35px;margin-right: 20px;color: #0f3993;font-weight: bold"
              class="el-icon-arrow-right"></i></a></h3>
        </div>
        <div style="margin-top: 15px">
          <ul>
            <li class="liA" v-for="item in importantList"><a class="AllLi" :href="item.articlePicUrl" target="_blank">
              <p class="PStyle">{{ item.articleTitle }}</p>
              <span class="spanStyleDJ">{{ item.articlePublishTime }}</span>
            </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mainCont_tab fl clear mainCont_tab_right">
        <div class="listTitleCenter">
          <h3><a href="/allDdzx"><i
              style="float: right;line-height: 35px;margin-right: 20px;color: #0f3993;font-weight: bold"
              class="el-icon-arrow-right"></i>党群建设</a></h3>
        </div>
        <div style="margin-top: 15px">
          <ul>
            <li class="liA" v-for="item in partyList"><a class="AllLi" :href="'/ddjsMain?id='+item.id" target="_blank">
              <p class="PStyle">{{ item.articleTitle }}</p>
              <span class="spanStyleDJ">{{ item.articlePublishTime }}</span>
            </a>
            </li>
          </ul>
        </div>
      </div>
      <!--      <div class="mainCont_tab fl clear">-->
      <!--        <div class="listTitleCenter">-->
      <!--          <h3><a href="#/allflfg" target="_blank"><i style="margin-top: 5px;float: right;"-->
      <!--                                                     class="el-icon-arrow-right"></i>法律法规</a></h3>-->
      <!--        </div>-->
      <!--        <div class="bd">-->
      <!--          <ul>-->
      <!--            <li v-for="item in LegalList"><a @click="$router.push({path:'/jtdt/'+item.id})" target="_blank"-->
      <!--                                             >{{-->
      <!--                item.articleTitle-->
      <!--              }}</a>-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="guang guanggao">
        <img src="/static/zzry/腰封.jpg"/>
        <!--        <div class="on">-->
        <!--          <p class="p1">-->
        <!--            <img src="./images/20190522112827117.png" alt="">-->
        <!--          </p>-->
        <!--          <p class="p2">-->
        <!--            <img src="./images/20190522112827117.png" alt="">-->
        <!--          </p>-->
        <!--        </div>-->
      </div>
      <div class="main_ywly fl mobileNone">
        <div class="listTitle" style="margin-bottom: 5px">
          <h3><a style="color:#002973;font-size: 25px" href="/ywly" target="_blank">主营业务</a></h3>
        </div>
        <div class="flexDiv">
          <div class="flexHD">
            <div class="flexHDUl">
              <ul class="hd clear">
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">基础测绘</a></span><span
                    class="pc_none">基础测绘</span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">工程测量</a></span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">不动产测绘</a></span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">自然资源调查与确权</a></span><span
                    class="pc_none">自然资源调查与确权</span></li>

                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">国土空间规划</a></span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">摄影测量与遥感</a></span><span
                    class="pc_none">摄影测量与遥感</span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">测绘地理信息大数据服务</a></span><span
                    class="pc_none">测绘地理信息大数据服务</span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">地质灾害防治与应急抢险</a></span><span
                    class="pc_none">地质灾害防治与应急抢险</span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">地质矿产勘查</a></span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">岩土勘察</a></span><span
                    class="pc_none">岩土勘察</span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">地图编制</a></span></li>
                <li class="cw"><span class="mobileNone"><a href="/ywly" target="_blank">档案数字化</a></span><span
                    class="pc_none">档案数字化</span></li>

              </ul>
            </div>
            <div class="flexImg">
              <ul class="bd">
                <li v-for="item in imagesUrl">
                  <a href="/ywly" target="_blank">
                    <img :src="item.url" alt=""/>
                  </a>
                </li>

              </ul>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="ellbt" style="height: 300px">
      <div class="listTitle" style="margin-top: 10px">
        <h3><a style="color:#002973;font-size: 25px" href="/kjcx" target="_blank">创新平台</a></h3>
      </div>
      <div class="carouselDiv">
        <el-carousel class="carouselStyle" :autoplay="false" :interval="4000" type="card" height="220px">
          <el-carousel-item v-for="item in innovationList" :key="item.id" style="height: 220px">
            <h3 class="medium"><a :href="'/kjcxMain?id='+item.id" target="_blank"><img
                style="width: 100%;height: 100%"
                :src="item.articlePicUrl" alt=""/>
            </a></h3>
            <span
                style="z-index: 999;position: absolute;bottom: 0px;background-color: rgba(0,0,0,0.6);width: 100%;height:30px;line-height: 30px;color: #fff;padding-left: 10px">{{
                item.articleTitle
              }}</span>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!--    <div class="ellbtZd">-->
    <!--      <div class="listTitle">-->
    <!--        <h3><a style="color:#002973;font-size: 25px">重点工程</a></h3>-->
    <!--      </div>-->
    <!--      <div class="imageStyleOne" style="width: 500px; margin-top: 20px;">-->
    <!--        <div class="ulStyle">-->
    <!--          <ul class="zdgcClassUl">-->
    <!--            <li class="zdgcClass" v-for="item in keyProjectsList"><a :href="'/zdgc?id='+item.id"-->
    <!--            >-->
    <!--              <p class="pZDStyle">{{ item.articleTitle }}</p>-->
    <!--            </a>-->
    <!--            </li>-->
    <!--          </ul>-->
    <!--        </div>-->

    <!--        <div class="separator"></div>-->
    <!--        <div class="imageStyle">-->
    <!--          <img src="../assets/重点项目.jpg" alt=""/>-->
    <!--        </div>-->
    <!--      </div>-->


    <!--    </div>-->
    <!-- content end -->
    <!-- footer start -->
    <div class="rt_footer" style="position: relative">
      <img style="position: absolute;top: -11px;left: 450px" src="../assets/import/友情链接.png"/>
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
<!--            <li class="yqlj"><a href="">友情链接</a></li>-->
            <li><a href="http://scdzdc.cn/" target="_blank">四川省地质调查研究院</a></li>
            <li><a href="https://www.mnr.gov.cn/" target="_blank">自然资源部</a></li>
            <li><a href="https://ngcc.cn/ngcc/" target="_blank">国家基础地理信息中心</a></li>
            <li><a href="http://dnr.sc.gov.cn/" target="_blank">四川省自然资源厅</a></li>
            <li><a href="http://scsm.mnr.gov.cn/" target="_blank">四川省测绘地理信息局</a></li>
            <li><a class="fwzc" href="/fwzc" target="_blank">服务支持</a></li>
          </ul>

        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="/lxwm" target="_blank">网站地图</a>|
              <a href="/lxwm" target="_blank">联系我们</a>|
              <!--              -->
            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省地质调查研究院测绘地理信息中心版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn/?lang=zh-cn#/Integrated/index" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh">川公网安备 51010502010510号 </a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<style scoped>
.ellbt {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.ellbtZd {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  /*主轴对齐方式**/
  justify-content: space-evenly;
  /*position: relative;*/
  margin-bottom: 30px;
}

.imageStyleOne {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imageStyle {
  flex: 1 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
}

.listTitle {

}

.ulStyle {
  flex: 1 0 692px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.separator {
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-right: 1px solid #ddd;
  margin-right: 5px;
  height: 400px;
  /*margin-top: 50px;*/

}

.liA {
  display: flex;
}


.AllLi {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*width: 590px;*/
  width: 648px;
  height: 39px;
  display: inline-block;
  padding-left: 20px;
}


.titleP1 {
  color: #333333;
  width: 210px;
  padding-left: 350px;
  padding-top: 30px;
  height: 30px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  /*opacity: 0.75;*/
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.el-carousel__item.is-animating {
  transition: opacity .6s ease-in-out !important;
}

.mainCont_tab .hd {
  width: 130px;
  font-size: 0;
  overflow: hidden;
}

.mainCont_tab {
  width: 590px;
  height: 230px;
  overflow: hidden;
  background-color: #f6f6f6;
  border-radius: 10px;
}

.mainCont_tab_right {
  margin-left: 20px;
}

.listTitleCenter {
  box-shadow: 0 3px 0 0 #0f3993;
  height: 35px;
  background: linear-gradient(to right, rgba(0, 126, 237, 1) 55%, rgb(12, 166, 255) 100%);
  border-radius: 12px;
  line-height: 35px;

}

.zdgcClassUl {
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
}

.zdgcClass {
  /*background: url("../../public/static/images/rt_indexList_d_v2020.png") 3px center no-repeat;*/
  line-height: 10px;
}

.pZDStyle {
  color: #666;
  width: 95%;
  font-size: 18px;
  line-height: 26px;
  box-sizing: border-box;
  /*margin-left: 10px;*/
}

.zdgcClass a {
  margin-left: 20px;
  color: #666;
  font-size: 20px !important;

}

.zdgcClass a:hover {
  color: #00a1ea;
}

.listTitleCenter h3 a {
  margin-left: 25px;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 22px !important;
}

::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 18px;
  font-weight: 900;
  color: rgba(0, 41, 115, 0.8);
  position: relative;
}

::v-deep .el-tabs__item.el-tabs__item.is-active {
  color: #00a1ea;
}

.PStyle {
  color: #666;
  position: relative;
  display: inline-block;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 26px;
}

.PStyle:hover {
  color: #00a1ea;
}

.PStyle:hover ~ .spanStyle {
  color: #00a1ea;
}

.PStyle:hover ~ .spanStyleDJ {
  color: #00a1ea;
}

.spanStyle {
  float: right;
  font-size: 16px;
  color: #999999;
  margin-top: -1px;
}

.spanStyleDJ {
  float: right;
  padding-right: 15px;
  font-size: 16px;
  color: #999999;
  line-height: 26px;
}


.flexDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  /*主轴对齐方式**/
  /*justify-content: space-evenly;*/

}

.flexBD {
  display: flex;
  /*放大比例**/
  flex-grow: 1;
  justify-content: space-between;
}

.flexHD {
  display: flex;
  /*放大比例**/
  flex-grow: 1;
  justify-content: space-between;
}

.flexHDUl {
  display: flex;
  flex: 2;
}

.flexImg {
  display: flex;
  flex: 1;
}

.el-divider--vertical {
  height: 25em;
  margin-left: 550px;
}

.nav-link {
  display: block;
  /*width: 170px;*/
  width: 200px;
  text-align: center;
}


.nav-link:hover {
  background-color: #0056a2 !important;
}

.nav-link-a {
  display: block;
  width: 186px;
  text-align: center;
}

.nav-link-a:hover {
  background-color: rgba(21, 152, 255, 0.5) !important;
  border-radius: 4px;
}

#topClass {
  background-image: url('@/assets/top.jpg');
  background-size: 100% 100%;
  width: 100%;
  text-align: center;
  display: table;
  height: 224px;
}

#topClass a {
  display: table-cell;
  vertical-align: middle;
}

#pTitle {
  position: absolute;
  top: 20%;
  left: 20%;
}

.fwzc {
  color: #0057fb;
  font-weight: bold;
  font-size: 20px;
}

/** 添加分割线*/
.fwzc:before {
  content: "|";
  float: left;
  margin-right: 20px;
  color: #94959E;
}

.fwzc:after {
  content: "|";
  float: right;
  margin-left: 20px;
  color: #94959E;
}

.guanggao {
  overflow: hidden;
}

.guang {
  width: 1200px;
  font-size: 0;
  position: relative;
}

.guang > img {
  margin-top: 10px;
  width: 100%;
}

.guanggao div.on {
  display: block;
}

.guang .p1 {
  width: 326px;
  position: absolute;
  left: 246px;
  top: 40px;
}

.guang .p2 {
  width: 582px;
  position: absolute;
  left: 450px;
  top: 85px;
}

.carouselDiv {
  width: 1000px;
  height: 250px;
  position: relative;
}

.carouselStyle {
  position: absolute;
  /*width: 150px;*/
  /*height: 100px;*/
  /*img左上角移动到html的中心*/
  left: 20%;
  width: 800px;
  /*img左上角向左上角分别移动自身的一半距离*/
  /*margin-left: -75px;*/
}

::v-deep .el-carousel__button {
  width: 8px !important;
  height: 8px !important;
}

::v-deep .el-carousel__indicators--horizontal {
  left: 90% !important;
  transform: translateX(0%);
}

.topA {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>

<script>
import axios from "axios";

export default {
  components: {},
  name: "Home",
  data() {
    return {
      activeName: 'first',
      imagesUrl: [
        //http://127.0.0.1:8000/img/W020220612734563063084.1e15b038.jpg
        {url: "/static/zzry/3基础测绘.jpg"},
        {url: "/static/zzry/6工程测量.jpg"},
        {url: "/static/zzry/5不动产测绘.jpg"},
        {url: "/static/zzry/9自然资源调查与确权.jpg"},
        {url: "/static/zzry/1国土空间规划.jpg"},
        {url: "/static/zzry/4测绘航空摄影测量与遥感.jpg"},
        {url: "/static/zzry/7测绘地理信息大数据服务.jpg"},
        {url: "/static/zzry/8地质灾害防治与应急抢险.jpg"},
        {url: "/static/zzry/10地质工程勘察.jpg"},
        {url: "/static/zzry/11岩土工程勘察.jpg"},
        {url: "/static/zzry/2地图编制.jpg"},
        {url: "/static/zzry/12档案数字化.jpg"},
      ],
      //新闻资讯
      NewsList: [],
      NewsListOne: {},
      //法律法规
      LegalList: [],
      //行业动态
      IndustryList: [],
      //党的建设
      partyList: [],
      //重要信息展示
      importantList: [],
      //创新平台
      innovationList: [],
      //创新平台
      keyProjectsList: [],
      //创新平台
      HomeCarouselList: [],
      //公示公告
      getAnnouncementList: [],
      item: ''

    };
  },
  methods: {
    checkForm1() {
      $("#searchword").val($.trim($("#searchword").val()));
      $("#searchword").val($("#searchword").val().replace(/请输入搜索内容/g, ''));
      $("#searchword").val($("#searchword").val().replace(/[\-\_\,\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g, ''));
      $("#searchword").val($("#searchword").val().replace(/script/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/iframe/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/update/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/alert/ig, ''));

      if ($("#searchword").val() == "") {
        alert("请输入搜索内容");
        return false;
      } else {
        return true;
      }
    },
    submit() {
      if (checkForm1()) {
        document.searchform.submit();
      }
    },
    changeAllColor() {
      const element = document.querySelector('.header_Nav.redBg');
      element.style.background = '#044ea8';
      // element.style.setProperty('background', '#044ea8', 'important');
      const element1 = document.querySelector('.mainContMes a.title');
      element1.style.color = '#044ea8';
    },
    // load() {
    //   axios.get("").then(res => {
    //     this.pictureList = res.data;
    //   })
    // }
  },
  method: {},
  mounted() {
    var _this = this;
    var articleItem = {
      pageNum: 1,
      pageSize: 7,
      articleType: 'hot'
    }
    var jsonText = JSON.stringify(articleItem);
    this.axios.post("/article/getHotArticle", jsonText, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      this.NewsList = res.data.data;
      console.log(res.data.data)
      this.axios.get("/article/getTopTitle", {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(datas => {
        if (datas.data.data != null) {
          _this.NewsListOne = datas.data.data
        } else {
          _this.NewsListOne = res.data.data[0]
        }
      })
    })
    var articleItem = {
      pageNum: 1,
      pageSize: 3,
      articleType: 'notic'
    }
    var jsonLegalList = JSON.stringify(articleItem);
    this.axios.post("/article/getNoticeArticle", jsonLegalList, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      this.LegalList = res.data.data;
    })
    var articleItem = {
      pageNum: 1,
      pageSize: 7,
      articleType: 'media'
    }
    var jsonIndustryList = JSON.stringify(articleItem);
    this.axios.post("/article/getMediaArticle", jsonIndustryList, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      this.IndustryList = res.data.data;
    })
    var getAnnouncementItem = {
      pageNum: 1,
      pageSize: 7,
      articleType: 'announcement'
    }
    var jsonAnnouncementItemList = JSON.stringify(getAnnouncementItem);
    this.axios.post("/article/getAnnouncement", jsonAnnouncementItemList, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      this.getAnnouncementList = res.data.data;
    })
    //党的建设查询
    var articleItem = {
      pageNum: 1,
      pageSize: 5,
      articleType: 'party'
    }
    var jsonIndustryList = JSON.stringify(articleItem);
    this.axios.post("/party/getPartyArticle", jsonIndustryList, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      //三条，后续5条
      console.log(res.data.data)
      this.partyList = res.data.data;
    })
    //重要信息查询
    var articleItem = {
      pageNum: 1,
      pageSize: 7,
    }
    var jsonIndustryList = JSON.stringify(articleItem);
    this.axios.post("/im/getArticle", jsonIndustryList, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      console.log(res.data.data, "1111111111111")
      this.importantList = res.data.data;
    })
    //创新平台信息查询
    var type = 'cxpt'
    this.axios.get("/in/getIn", {params: {type}}).then(res => {
      console.log(res, "22222222222222")
      this.innovationList = res.data.data;
    })
    //重点工程信息查询
    this.axios.get("/key/getAll").then(res => {
      this.keyProjectsList = res.data.data;
    })
    //首页轮播
    this.axios.get("/home/getAll").then(res => {
      this.HomeCarouselList = res.data.data;
      this.item = HomeCarouselList.get(0).articleTitle
    })


    // var elementById = document.getElementById("changes");
    // elementById.onfocus = function () {
    //   alert("获取到焦点")
    // };
    // this.load();
    // this.changeAllColor();
  },

  activated() {
    this.$router.replace({path: '/'})
  },

  created() {
    //单参
    // axios.get("https://www.bilibili.com:6002/path?id=33").then(res => {
    //   this.pictureList = res.data;
    // })
    //多参
    // axios.get("https://www.bilibili.com:6002/path,{params:{id=33}").then(res => {
    //   this.pictureList = res.data;
    // })
  }
}
</script>
