<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear">
        <div class="header_logo clear">
          <a class="header_logo1 fl" href="/"><img src="./images/logo.png" alt=""/></a>
          <p class="header_yy pcNone fl" style="display:none"><a href="/">中</a>|<a href="/en/">EN</a></p>
          <a href="javascript:void(0)" class="headerLoadMore pcNone fl"></a>
          <a href="javascript:void(0)" class="headerCloseMore pcNone fl"></a>
          <form name="searchform" class="isdc_xiangyou" style="float:right;    margin-right: 0.2rem;" action="/search/"
                method="get" onsubmit="return checkForm1()">
            <p class="header_top_mes mobileNone"><a class="qy_email" href="http://mail.crtamg.com.cn"
                                                    target="_blank">企业邮箱</a>
              <!-- <a href="/">中</a>|<a href="/en/">EN</a> -->
            </p>
            <div class="searchBox fr">
              <a href="javascript:void(0)"></a>
              <div data-open="false" style="display: block;">
                <input class="search_txt flx" type="text" name="searchword" placeholder="请输入搜索关键词" id="searchword"
                       autocomplete="off"/>
                <input class="search_btn fr" value="" type="submit"/>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="rt on"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a href="#/gywm/">关于我们</a>
              <div class="gx_headNav_menu">
                <a href="#/gywm/">单位简介</a>
                <a href="src/views/gywm/gltd/">机构设置</a>
                <a href="src/views/gywm/zzjg/">资质荣誉</a>
              </div>
            </li>
            <li class="nav-link">
              <a href="#/allXwzx">新闻咨询</a>
            </li>
            <li class="rt3">
              <a href="#/kjcx">科技创新</a>
            </li>
            <li class="rt3">
              <a href="#/ywly">业务领域</a>
            </li>
            <li class="rt4">
              <a href="#/allDdzx">党的建设</a>
              <div class="gx_headNav_menu">
                <a href="./ddjs/djdt/">党建动态</a>
                <a href="./ddjs/djzt/">党建专题</a>
                <a href="./ddjs/djjy/">党建经验</a>
                <a href="./ddjs/qngz/">团青活动</a>
                <a href="./ddjs/zgzj/">工会工作</a>
                <a href="./ddjs/tqgz/" style="border:none">基层风采</a>
              </div>
            </li>
            <li class="rt5">
              <a href="#/fwzc">服务支持</a>
            </li>

            <li class="rt8">
              <a href="#/lxwm/">联系我们</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->
    <div class="txtMarqueeBox">
      <div class="txtMarquee-left">
        <div class="bd">
          <ul>
            <li>
              <img src="../views/ddjs/images/P020201029553226170614.png" alt=""/>
            </li>
            <li>
              <img src="../views/ddjs/images/P020201029553263955571.png" alt=""/>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- content start -->
    <div class="ejAbtrtC gx_ejAbtrtC gx_ejAbtrtC_dj">
      <div class="gx_ejCon">
        <div class="gx_nav">
          <span>当前位置：</span>
          <a href="../" class="default">首页</a>
          <a class="active">党群建设</a>
        </div>
        <div class="gx_modular1 clearfix">
          <div class="gx_new_slide">
            <div class="slideBox">
              <div class="bd">
                <el-carousel trigger="click" height="380px">
                  <el-carousel-item v-for="item in 4" :key="item">
                    <h3 class="small"><a href="./xwzx/jtdt/202304/t20230419_2084.html" target="_blank"><img
                        src="./xwzx/jtdt/202304/W020230422593446892976.jpg" alt=""/>
                      <p>中国融通集团召开2023年第一季度经营情况分析会</p></a></h3>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
          <div class="gx_new_group">
            <div class="gx_title clearfix gx_title_sy">
              <div class="gx_tle_name">主题教育</div>
              <a href="#/allDdzx" title="" alt="" class="gx_tle_more">更多</a>
            </div>
            <ul class="gx_group_list">

              <li><a href="#/jtdt" target="_blank" title="中国融通集团高级职称评审委员会获人社部备案">中国融通集团高级职称评审委员会获人社部备案</a>
              </li>

              <li><a href="./xwzx/jtdt/202304/t20230421_2099.html" target="_blank" title="中国融通集团召开2023年法治工作会">中国融通集团召开2023年法治工作会</a>
              </li>

              <li><a href="./xwzx/jtdt/202304/t20230419_2084.html" target="_blank" title="中国融通集团召开2023年第一季度经营情况分析会">中国融通集团召开2023年第一季度经营情况分析会</a>
              </li>

              <li><a href="./xwzx/jtdt/202304/t20230418_2081.html" target="_blank" title="中国融通集团董事赴上海、江苏调研">中国融通集团董事赴上海、江苏调研</a>
              </li>

              <li><a href="./xwzx/jtdt/202304/t20230418_2080.html" target="_blank" title="中国融通集团举办2023年度第1期新员工入职培训班">中国融通集团举办2023年度第1期新员工入职培训班</a>
              </li>

              <li><a href="./xwzx/jtdt/202304/t20230418_2079.html" target="_blank" title="中国融通集团开展“4·15”全民国家安全教育日活动">中国融通集团开展“4·15”全民国家安全教育日活动</a>
              </li>

            </ul>
            <ul class="gx_group_list_m">

              <li><span>[2023-04-23]</span><a href="./xwzx/jtdt/202304/t20230424_2121.html">中国融通集团高级职称评审委员会获人社部备案</a>
              </li>

              <li><span>[2023-04-20]</span><a href="./xwzx/jtdt/202304/t20230421_2099.html">中国融通集团召开2023年法治工作会</a></li>

              <li><span>[2023-04-19]</span><a href="./xwzx/jtdt/202304/t20230419_2084.html">中国融通集团召开2023年第一季度经营情况分析会</a>
              </li>

              <li><span>[2023-04-18]</span><a href="./xwzx/jtdt/202304/t20230418_2081.html">中国融通集团董事赴上海、江苏调研</a></li>

              <li><span>[2023-04-18]</span><a
                  href="./xwzx/jtdt/202304/t20230418_2080.html">中国融通集团举办2023年度第1期新员工入职培训班</a></li>

              <li><span>[2023-04-15]</span><a
                  href="./xwzx/jtdt/202304/t20230418_2079.html">中国融通集团开展“4·15”全民国家安全教育日活动</a></li>

            </ul>
            <a href="./xwzx/jtdt/" title="" alt="" class="gx_more">更多</a>
          </div>
        </div>
        <div class="gx_modular2 clearfix">
          <div class="gx_dynamics">
            <div class="gx_title clearfix gx_title_sy">
              <div class="gx_tle_name">党群建设</div>
              <a href="./xwzx/qydt/" title="" alt="" class="gx_tle_more">更多</a>
            </div>
            <ul class="gx_dynamics_list">

              <li>
                <div class="gx_dyImg">
                  <a href="./xwzx/qydt/202304/t20230423_2120.html" target="_blank">
                    <img src="./xwzx/qydt/202304/W020230424608204188108.png" alt=""/>
                  </a>
                </div>
                <div class="gx_dycon">
                  <a href="./jtdt" target="_blank">
                    <h3 title="融通旅发开展成员酒店消防安全专项整治工作">融通旅发开展成员酒店消防安全专项整治工作</h3>
                    <p>为深入贯彻落实习近平总书记关于安全生产重要论述，按照集团公司2023年安全环保工作总体要求，融通旅发结合安全环保年...</p>
                  </a>
                </div>
              </li>

              <li>
                <div class="gx_dyImg">
                  <a href="./xwzx/qydt/202304/t20230418_2077.html" target="_blank">
                    <img src="./xwzx/qydt/202304/W020230418846899121134.jpg" alt=""/>
                  </a>
                </div>
                <div class="gx_dycon">
                  <a href="./xwzx/qydt/202304/t20230418_2077.html" target="_blank">
                    <h3 title="融通文教以信息化建设点燃科技创新“引擎”">融通文教以信息化建设点燃科技创新“引擎”</h3>
                    <p>党的二十大报告指出，必须坚持科技是第一生产力、人才是第一资源、创新是第一动力。融通文教将科技作为2023年度管理提...</p>
                  </a>
                </div>
              </li>

              <li>
                <div class="gx_dyImg">
                  <a href="./xwzx/qydt/202304/t20230418_2076.html" target="_blank">
                    <img src="./xwzx/qydt/202304/W020230418838256959329.jpg" alt=""/>
                  </a>
                </div>
                <div class="gx_dycon">
                  <a href="./xwzx/qydt/202304/t20230418_2076.html" target="_blank">
                    <h3 title="融通旅发成都祥宇宾馆推行精益化管理显成效">融通旅发成都祥宇宾馆推行精益化管理显成效</h3>
                    <p>融通旅发成都祥宇宾馆积极顺应集团公司“三个转变”形势要求，主动对标行业先进，探索提升精益化管理的方法路径，在优...</p>
                  </a>
                </div>
              </li>

              <li>
                <div class="gx_dyImg">
                  <a href="./xwzx/qydt/202304/t20230418_2078.html" target="_blank">
                    <img src="./xwzx/qydt/202304/W020230418835096131295.png" alt=""/>
                  </a>
                </div>
                <div class="gx_dycon">
                  <a href="./xwzx/qydt/202304/t20230418_2078.html" target="_blank">
                    <h3 title="融通农发北京区域公司夯基固本助推经营发展提质增效">融通农发北京区域公司夯基固本助推经营发展提质增效</h3>
                    <p>融通农发北京区域公司认真学习宣传贯彻党的二十大精神，牢牢稳住基本盘，培育壮大优势产业，大力推动重点项目，为高质...</p>
                  </a>
                </div>
              </li>

              <li>
                <div class="gx_dyImg">
                  <a href="./xwzx/qydt/202304/t20230417_2074.html" target="_blank">
                    <img src="./xwzx/qydt/202304/W020230417749294494185.gif" alt=""/>
                  </a>
                </div>
                <div class="gx_dycon">
                  <a href="./xwzx/qydt/202304/t20230417_2074.html" target="_blank">
                    <h3 title="融通地产湖北公司深耕细作提品质 务实笃行谋发展">融通地产湖北公司深耕细作提品质 务实笃行谋发展</h3>
                    <p>融通地产湖北公司认真学习宣传贯彻党的二十大精神，扎根鄂陕大地，聚焦主营业务，充分发挥资源禀赋优势，做强品牌、提...</p>
                  </a>
                </div>
              </li>

            </ul>
            <ul class="gx_group_list_m">

              <li><span>[2023-04-21]</span><a href="./xwzx/qydt/202304/t20230423_2120.html">融通旅发开展成员酒店消防安全专项整治工作</a>
              </li>

              <li><span>[2023-04-21]</span><a href="./xwzx/qydt/202304/t20230423_2119.html">安防国际以党的二十大精神为指引
                聚焦安全担使命提质增效当先锋</a></li>

              <li><span>[2023-04-19]</span><a href="./xwzx/qydt/202304/t20230419_2085.html">融通农发乌鲁木齐区域公司扎实推进精益化管理</a>
              </li>

              <li><span>[2023-04-17]</span><a href="./xwzx/qydt/202304/t20230418_2077.html">融通文教以信息化建设点燃科技创新“引擎”</a>
              </li>

              <li><span>[2023-04-17]</span><a href="./xwzx/qydt/202304/t20230418_2076.html">融通旅发成都祥宇宾馆推行精益化管理显成效</a>
              </li>

              <li><span>[2023-04-15]</span><a href="./xwzx/qydt/202304/t20230418_2078.html">融通农发北京区域公司夯基固本助推经营发展提质增效</a>
              </li>

            </ul>
            <a href="./xwzx/qydt/" title="" alt="" class="gx_more">更多</a>
          </div>
          <div class="gx_col_vid">
            <div class="gx_column">
              <div class="gx_title clearfix gx_title_sy">
                <div class="gx_tle_name">专题栏目</div>
                <a href="./xwzx/ztlm/" title="" alt="" class="gx_tle_more">更多</a>
              </div>
              <div class="slideBox">
                <div class="bd">
                  <ul>
                    <li>
                      <a href="/xwzx/ztlm/sxswsjhd/index.html" target="_blank"><img
                          src="./xwzx/ztlm/202206/W020220722787518437558.png" alt=""/></a>

                    </li>
                    <li><a href="/xwzx/ztlm/jzjs/index.html" target="_blank"><img
                        src="./xwzx/ztlm/202206/W020220616669847868312.png" alt=""/></a></li>
                  </ul>
                </div>
                <div class="hd">
                  <ul>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div class="gx_col_img">

                <a href="/xwzx/ztlm/sxswsjhd/index.html"><img src="./xwzx/ztlm/202206/W020220722787518437558.png"
                                                              alt=""/></a>

                <a href="/xwzx/ztlm/jzjs/index.html"><img src="./xwzx/ztlm/202206/W020220616669847868312.png"
                                                          alt=""/></a>

              </div>
              <a href="./xwzx/ztlm/" title="" alt="" class="gx_more">更多</a>
            </div>
            <div class="gx_video">
              <div class="gx_title clearfix gx_title_sy">
                <div class="gx_tle_name">视频专区</div>
                <a href="./xwzx/spxw/" title="" alt="" class="gx_tle_more">更多</a>
              </div>
              <div class="gx_vid">

                <a href="./xwzx/spxw/202302/t20230201_1917.html" target="_blank"><img
                    src="./xwzx/spxw/202302/W020230201305397723808.png" alt=""/><span></span></a>


              </div>
              <div class="gx_vid_m">

                <a href="./xwzx/spxw/202302/t20230201_1917.html"><img
                    src="./xwzx/spxw/202302/W020230201305397723808.png" alt=""/><span></span></a>

                <a href="./xwzx/spxw/202302/t20230201_1918.html"><img
                    src="./xwzx/spxw/202302/W020230201305400811862.png" alt=""/><span></span></a>

              </div>
              <a href="./xwzx/spxw/" title="" alt="" class="gx_more">更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content end -->
    <!-- footer start -->
    <div class="rt_footer">
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
            <li class="yqlj"><a href="">友情链接</a></li>
            <li><a href="">自然资源部</a></li>
            <li><a href="">国家基础地理信息中心</a></li>
            <li><a href="">四川省自然资源厅</a></li>
            <li><a href="">四川省测绘地理信息局</a></li>
          </ul>
        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="./wzdt/" target="_blank">网站地图</a>|
              <a href="./lxwm/" target="_blank">联系我们</a>|

            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省煤田测绘工程院版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh"></a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>


<script>
import axios from "axios";

var ary = location.href.split("&");
export default {
  components: {},
  name: "DdjsView",
  data() {
    return {
      pictureList: []
    };
  },
  methods: {
    checkForm1() {
      $("#searchword").val($.trim($("#searchword").val()));
      $("#searchword").val($("#searchword").val().replace(/请输入搜索内容/g, ''));
      $("#searchword").val($("#searchword").val().replace(/[\-\_\,\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g, ''));
      $("#searchword").val($("#searchword").val().replace(/script/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/iframe/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/update/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/alert/ig, ''));

      if ($("#searchword").val() == "") {
        alert("请输入搜索内容");
        return false;
      } else {
        return true;
      }
    },
    submit() {
      if (checkForm1()) {
        document.searchform.submit();
      }
    },
    load() {
      axios.get("").then(res => {
        this.pictureList = res.data;
      })
    },
    onloadIntroduce() {
      $(".txtMarquee-left").slide({
        mainCell: ".bd ul",
        autoPlay: true,
        effect: "leftMarquee",
        interTime: 20,
        trigger: "click",
        vis: 2
      });
    }
  },
  method: {},
  mounted: function () {
    this.load();
    this.onloadIntroduce();
  }
}
</script>

<style scoped>
/*清除样式*/
.clearfix {
  zoom: 1;
  _height: 1px;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.gx_title_sy {
  width: 485px;
  height: 52px;
  background: url("../../public/static/images/gx_dangbg.png") no-repeat;
  /* background-size: 436px 52px; */
  margin: -4px 0 0 -4px;
}

/*融通-新闻中心 start */
.gx_ejAbtrtC {
  background: none;
  padding-bottom: 0;
}

.gx_ejCon {
  width: 1200px;
  margin: 0 auto;
}

.gx_nav {
  border-bottom: 1px solid #e0e0e0;
  height: 55px;
  line-height: 55px;
  font-size: 0;
}

.gx_nav span,
.gx_nav a {
  display: inline-block;
  font-size: 16px;
}

.gx_nav span,
.gx_nav a {
  color: #808080;
}

.gx_nav a.default {
  margin-right: 6px;
}

.gx_nav a.default:after {
  content: '>';
  margin-left: 6px;
}

.gx_nav a.active {
  cursor: auto;
}

.gx_modular1 {
  padding-top: 50px;
}

.gx_modular1 .gx_new_slide {
  float: left;
  width: 640px;
  height: 370px;
}

.gx_modular1 .slideBox {
  width: 640px;
  height: 370px;
  overflow: hidden;
  position: relative;
}

.gx_modular1 .slideBox .hd {
  height: 8px;
  position: absolute;
  right: 20px;
  bottom: 14px;
  z-index: 1;
}

.gx_modular1 .slideBox .hd ul {
  float: right;
  zoom: 1;
}

.gx_modular1 .slideBox .hd ul li {
  float: left;
  margin-left: 8px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  cursor: pointer;
}

.gx_modular1 .slideBox .hd ul li.on {
  background: #5976ba;
  position: relative;
  top: -2px;
}

.gx_modular1 .slideBox .bd {
  position: relative;
  height: 100%;
  z-index: 0;
}

.gx_modular1 .slideBox .bd li {
  zoom: 1;
  vertical-align: middle;
  position: relative;
}

.gx_modular1 .slideBox .bd img {
  width: 640px;
  height: 370px;
  display: block;
  object-fit: cover;
}

.gx_modular1 .slideBox .bd p {
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  left: 21px;
  bottom: 0px;
  z-index: 1;
  font-weight: bold;
  width: 100%;

  height: 36px;
  line-height: 36px;
  font-size: 15px;
}

.gx_header_Nav.redBg ul li .gx_headNav_menu a {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  box-shadow: none;
}

.gx_modular1 .gx_new_group,
.gx_modular2 .gx_col_vid,
.gx_modular3 .gx_focus {
  float: right;
  width: 480px;
}

.gx_title .gx_tle_name {
  float: left;
  color: #333333;
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
}

.gx_title .gx_tle_line {
  float: left;
  width: 288px;
  height: 6px;
  background: #f7f7f7;
  margin: 10px 0 0 20px;
}

.gx_title .gx_tle_line span {
  width: 70px;
  height: 6px;
  background: #5976ba;
  display: block;
}

.gx_title .gx_tle_more {
  float: right;
  display: block;
  color: #666666;
  font-size: 16px;
  line-height: 1;
  margin-top: 4px;
  padding-right: 24px;
}

.gx_group_list {
  padding-top: 9px;
}

.gx_group_list li {
  padding: 16px 0;
  overflow: hidden;
}

.gx_group_list li + li {
  border-top: 1px dashed #eeeeee;
}

.gx_group_list li + li + li + li + li + li {
  padding-bottom: 0;
}

.gx_group_list.rtwy_list {
  overflow: hidden;
}

.gx_group_list.rtwy_list li {
  border-top: none;
  width: 50%;
  float: left;
  border-bottom: 1px dashed #eeeeee;
}

.gx_group_list.rtwy_list li + li + li + li + li + li {
  padding-bottom: 20px;
}

.gx_group_list li a {
  float: left;
  color: #333333;
  font-size: 18px;
  display: block;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 460px;
}

.gx_right .gx_group_list li a {
  max-width: 390px;
}

.gx_group_list li span {
  float: right;
  color: #999999;
  font-size: 16px;
  display: inline-block;
}

.gx_modular2 {
  padding: 35px 0 38px;
}

.gx_modular2 .gx_dynamics,
.gx_modular3 .gx_owned_assets {
  float: left;
  width: 640px;
}

.gx_modular2 .gx_dynamics .gx_title .gx_tle_line,
.gx_modular3 .gx_owned_assets .gx_title .gx_tle_line {
  width: 450px;
}

.gx_modular2 .gx_dynamics_list {
  padding-top: 11px;
}

.gx_modular2 .gx_dynamics_list li {
  overflow: hidden;
  padding: 20px 0;
}

.gx_modular2 .gx_dynamics_list li + li {
  border-top: 1px dashed #ebebeb;
}

.gx_modular2 .gx_dynamics_list li .gx_dyImg {
  float: left;
}

.gx_modular2 .gx_dynamics_list li .gx_dyImg,
.gx_modular2 .gx_dynamics_list li .gx_dyImg img {
  width: 151px;
  height: 100px;
  object-fit: cover;
}

.gx_modular2 .gx_dynamics_list li .gx_dycon {
  float: right;
  width: 458px;
}

.gx_modular2 .gx_dynamics_list li .gx_dycon h3 {
  color: #333333;
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  padding: 10px 0 15px;
}

.gx_modular2 .gx_dynamics_list li .gx_dycon p {
  color: #666666;
  font-size: 16px;
  line-height: 26px;
}

.gx_modular2 .gx_dynamics_list li .gx_dycon p span {
  color: #5976ba;
  font-size: 16px;
}

.gx_modular2 .slideBox {
  width: 480px;
  height: 326px;
  overflow: hidden;
  position: relative;
  background: #f7f7f7;
  margin: 10px 0 60px;
}

.gx_modular2 .slideBox .hd {
  width: 100%;
  height: 8px;
  position: absolute;
  left: 0px;
  bottom: 32px;
  z-index: 3;
  text-align: center;
}

.gx_modular2 .slideBox .hd ul {
  overflow: hidden;
  zoom: 1;
}

.gx_modular2 .slideBox .hd ul li {
  width: 8px;
  height: 8px;
  background: #cccccc;
  display: inline-block;
}

.gx_modular2 .slideBox .hd ul li a {
  width: 8px;
  height: 8px;
  display: block;
}

.gx_modular2 .slideBox .hd ul li + li {
  margin-left: 8px;
}

.gx_modular2 .slideBox .hd ul li.on {
  background: #5976ba;
}

.gx_modular2 .slideBox .bd {
  width: 363px;
  position: relative;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
}

.gx_modular2 .slideBox .bd li {
  width: 363px;
  zoom: 1;
  vertical-align: middle;
  position: relative;
}

.gx_modular2 .slideBox .bd li a {
  display: block;
  height: 108px;
  overflow: hidden;
  margin-top: 30px;
}

.gx_modular2 .slideBox .bd img {
  width: 100%;
  display: block;
}

.gx_modular2 .gx_vid {
  width: 480px;
  height: 264px;
  background: #f7f7f7;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
}

.gx_modular2 .gx_vid img {
  margin: 30px auto 0;
  width: 363px;
  height: 204px;
  display: block;
}

.gx_modular2 .gx_vid span {
  height: 60px;
  width: 60px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
}

.gx_modular3 {
  padding-bottom: 80px;
}

.gx_modular1 .swiper-container,
.gx_group_list_m,
.gx_more,
.gx_col_img,
.gx_vid_m,
.gx_dynamics_list_m {
  display: none;
}

/*融通-新闻中心 end */

/*融通频道-关于融通 start */
.gx_modular4 .gx_title .gx_tle_line,
.gx_modular5 .gx_title .gx_tle_line,
.gx_modular6 .gx_title .gx_tle_line {
  width: 1007px;
}

.gx_modular4 {
  padding-top: 59px;
}

.gx_modular4 .gx_intro {
  padding-top: 28px;
}

.gx_modular4 .gx_intro img {
  width: 1200px;
  height: 258px;
  margin-bottom: 16px;
}

.gx_modular4 .gx_intro p {
  color: #333333;
  font-size: 18px;
  line-height: 35px;
}

.gx_modular5 {
  padding: 40px 0 50px;
}

.gx_modular5 .picScroll-left {
  overflow: hidden;
  position: relative;
  margin-top: 31px;
}

.gx_modular5 .picScroll-left .hd {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 66px;
  left: 0;
  z-index: 5;
}


.gx_modular5 .picScroll-left .bd {
  width: 1058px;
  margin: 0 auto;
  overflow: hidden;
}

.gx_modular5 .picScroll-left .bd ul {
  overflow: hidden;
  zoom: 1;
}

.gx_modular5 .picScroll-left .bd ul li {
  float: left;
  text-align: center;
  width: 163px;
  margin-right: 60px;
}

.gx_modular5 .picScroll-left .bd ul li img {
  width: 163px;
  height: 217px;
  display: block;
  border: 1px solid #ececec;
}

.gx_modular5 .picScroll-left .bd ul li h3 {
  color: #333333;
  font-size: 20px;
  line-height: 1;
  font-weight: normal;
  padding: 20px 0 16px;
}

.gx_modular5 .picScroll-left .bd ul li p {
  color: #808080;
  font-size: 16px;
  line-height: 19px;
}

.gx_modular6 .gx_structure {
  border: 1px solid #d2d9e9;
  margin-top: 30px;
  text-align: center;
  padding: 30px 1px;
}

.gx_modular6 .gx_structure img {
  max-width: 100%;
}

.gx_modular6 {
  padding-bottom: 80px;
}

.gx_intro_m,
.pic_list,
.gx_structure_m {
  display: none;
}

.gx_modular5 .picScroll-left .tempWrap {
  margin: 0 auto;
}

.gx_modular5 .picScroll-left .tempWrap ul {
  width: 1150px;
}

/*融通频道-关于融通 end */

/*融通频道-党建 start */
.gx_ejAbtrtC_dj {
  background: #f0f0f0;
}

.gx_ejConBox {
  padding: 40px 0 68px;
}


.gx_pub_tle {
  padding: 33px 47px 68px 30px;
}

.gx_pub_tle h2 {
  color: #ffffff;
  font-size: 30px;
  float: left;
  padding-left: 47px;
}

.gx_pub_tle .gx_nav {
  float: right;
  border: 0;
  height: auto;
  line-height: normal;
}

.gx_modular7 .slideBox {
  width: 580px;
  height: 350px;
  overflow: hidden;
  position: relative;
}

.gx_modular7 .slideBox .hd {
  height: 8px;
  position: absolute;
  right: 20px;
  bottom: 21px;
  z-index: 1;
}

.gx_modular7 .slideBox .hd ul {
  float: right;
  zoom: 1;
}

.gx_modular7 .slideBox .hd ul li {
  float: left;
  margin-left: 8px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  cursor: pointer;
}

.gx_modular7 .slideBox .hd ul li.on {
  background: #ce0212;
  position: relative;
  top: -2px;
}

.gx_modular7 .slideBox .bd {
  position: relative;
  height: 100%;
  z-index: 0;
}

.gx_modular7 .slideBox .bd li {
  zoom: 1;
  vertical-align: middle;
  position: relative;
}

.gx_modular7 .slideBox .bd img {
  width: 580px;
  height: 350px;
  display: block;
  object-fit: cover;
}

.gx_modular7 .slideBox .bd p {
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  left: 21px;
  bottom: 17px;
  z-index: 1;
  font-weight: bold;
  width: 100%;
  text-indent: 14px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 18px;
  color: #ffffff;
}


.gx_title_sy .gx_tle_name {
  float: left;
  color: #ce0212;
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  padding: 26px 0 0 101px;
  line-height: normal;
}

.gx_title_sy .gx_tle_more {
  float: right;
  display: block;
  color: #666666;
  font-size: 16px;
  line-height: 1;
  margin: 29px 12px 0 0;
  padding-right: 22px;
}

.gx_group_list_dj li {
  padding: 16px 0;
}

.gx_group_list_dj li + li {
  border-top: 1px dashed #e0e0e0;
}

.gx_group_list_dj li a {
}

.gx_group_list_dj li + li + li + li + li + li {
  padding-bottom: 20px;
}

.gx_group_list_dj li + li + li + li + li + li + li + li,
.gx_pictures .gx_imgBox p {
  width: 427px;
  height: 37px;
  line-height: 37px;
  background: #ce0212;
  color: #ffffff;
  font-size: 18px;
  padding-left: 10px;
  position: absolute;
  left: 50%;
  bottom: 10px;
  margin-left: -218.5px;
}

.gx_zg_img {
  margin: 20px 0 0 7px;
}

.gx_zg_img,
.gx_zg_img img {
  width: 510px;
  height: 214px;
}

.gx_modular8 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.gx_modular9 {
  padding-top: 50px;
}

.gx_modular9 .picScroll-left {
  overflow: hidden;
  position: relative;
  margin-top: 25px;
}

.gx_modular9 .picScroll-left .hd {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 47px;
  left: 0;
  z-index: 1;
}

.gx_modular9 .picScroll-left .bd {
  width: 1054px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 3;
}

.gx_modular9 .picScroll-left .bd ul {
  overflow: hidden;
  zoom: 1;
}

.gx_modular9 .picScroll-left .bd ul li {
  float: left;
  width: 241px;
  margin-right: 30px;
}

.gx_modular9 .picScroll-left .bd ul li img {
  width: 241px;
  height: 160px;
  display: block;
}

.swiper-container {
  display: none;
}

/*融通频道-党建 end */

/*顶部导航下拉菜单 start */
.gx_rt_header {
  position: relative;
  z-index: 200;
}

.gx_header_Nav ul li {
  position: relative;
}

.gx_header_Nav ul li .gx_headNav_menu {
  position: absolute;
  top: 60px;
  left: -44px;
  z-index: 100;
  width: 118px;
  padding: 13px 26px 15px;
  box-shadow: 0 5px 8px rgba(99, 99, 99, 0.2);
  text-align: center;
  display: none;
}

.gx_header_Nav ul li .gx_headNav_menu a {
  display: block;
  color: #333333;
  font-size: 16px;
  border-bottom: 1px dashed #7d89a3;
  height: 49px;
  line-height: 49px;
}

.gx_header_Nav ul li .gx_headNav_menu a:hover {
  color: #b81c22;
  font-weight: bold;
}

/*顶部导航下拉菜单 end */

.ejZzjg .ejRb {
  padding: 0;
}

@media screen and (max-width: 1440px) {
  /*顶部导航下拉菜单 start */
  .gx_header_Nav ul li .gx_headNav_menu {
    top: 50px;
    left: -38px;
    width: 100px;
    padding: 10px 21px;
  }

  .gx_header_Nav ul li .gx_headNav_menu a {
    font-size: 13px;
    height: 40px;
    line-height: 40px;
  }

  /*顶部导航下拉菜单 end */
  /*融通-新闻中心 start */
  .gx_ejCon {
    width: 1000px;
  }

  .gx_nav {
    height: 46px;
    line-height: 46px;
  }

  .gx_nav span,
  .gx_nav a {
    font-size: 13px;
  }

  .gx_modular1 {
    padding-top: 41px;
  }

  .gx_modular1 .gx_new_slide {
    width: 533px;
    height: 310px;
  }

  .gx_modular1 .slideBox {
    width: 533px;
    height: 310px;
  }

  .gx_modular1 .slideBox .hd {
    height: 7px;
    right: 17px;
    bottom: 14px;
  }

  .gx_modular1 .slideBox .hd ul li {
    margin-left: 6px;
    width: 7px;
    height: 7px;
  }

  .gx_modular1 .slideBox .bd img {
    width: 533px;
    height: 310px;
  }

  .gx_modular1 .slideBox .bd p {
    font-size: 14px;
  }

  .gx_modular1 .gx_new_group,
  .gx_modular2 .gx_col_vid,
  .gx_modular3 .gx_focus {
    width: 400px;
  }

  .gx_title .gx_tle_name {
    font-size: 19px;
    margin-top: -3px;
  }

  .gx_title .gx_tle_line {
    width: 241px;
    height: 4px;
    margin: 8px 0 0 16px;
  }

  .gx_title .gx_tle_line span {
    width: 58px;
    height: 4px;
  }

  .gx_title .gx_tle_more {
    font-size: 14px;
    margin-top: 2px;
    padding-right: 20px;
  }

  .gx_group_list {
    padding-top: 5px;
  }

  .gx_group_list li {
    padding: 15px 0;
  }

  .gx_group_list li a {
    font-size: 14px;
    padding-left: 17px;
  }

  .gx_group_list li span {
    font-size: 12px;
  }

  .gx_modular2 {
    padding: 33px 0;
  }

  .gx_modular2 .gx_dynamics,
  .gx_modular3 .gx_owned_assets {
    width: 533px;
  }

  .gx_modular2 .gx_dynamics .gx_title .gx_tle_line,
  .gx_modular3 .gx_owned_assets .gx_title .gx_tle_line {
    width: 375px;
  }

  .gx_modular2 .gx_dynamics_list {
    padding-top: 9px;
  }

  .gx_modular2 .gx_dynamics_list li {
    padding: 16px 0;
  }

  .gx_modular2 .gx_dynamics_list li .gx_dyImg,
  .gx_modular2 .gx_dynamics_list li .gx_dyImg img {
    width: 125px;
    height: 85px;
  }

  .gx_modular2 .gx_dynamics_list li .gx_dycon {
    width: 383px;
  }

  .gx_modular2 .gx_dynamics_list li .gx_dycon h3 {
    font-size: 14px;
    padding: 8px 0 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .gx_modular2 .gx_dynamics_list li .gx_dycon p {
    font-size: 13px;
    line-height: 22px;
  }

  .gx_modular2 .gx_dynamics_list li .gx_dycon p span {
    font-size: 13px;
  }

  .gx_modular2 .slideBox {
    width: 400px;
    height: 272px;
    margin: 8px 0 50px;
  }

  .gx_modular2 .slideBox .hd {
    width: 100%;
    height: 7px;
    left: 0px;
    bottom: 28px;
  }

  .gx_modular2 .slideBox .hd ul li,
  .gx_modular2 .slideBox .hd ul li a {
    width: 7px;
    height: 7px;
  }

  .gx_modular2 .slideBox .hd ul li + li {
    margin-left: 6px;
  }

  .gx_modular2 .slideBox .bd {
    width: 304px;
  }

  .gx_modular2 .slideBox .bd li {
    width: 304px;
  }

  .gx_modular2 .slideBox .bd li a {
    height: 90px;
    margin-top: 24px;
  }

  .gx_modular2 .gx_vid {
    width: 400px;
    height: 220px;
    margin-top: 9px;
  }

  .gx_modular2 .gx_vid img {
    margin: 24px auto 0;
    width: 304px;
    height: 171px;
  }

  .gx_modular3 {
    padding-bottom: 66px;
  }

  .gx_group_list.rtwy_list li + li + li + li + li + li {
    padding-bottom: 18px;
  }

  /*融通-新闻中心 end */
  /*融通频道-关于融通 start */
  .gx_modular4 .gx_title .gx_tle_line,
  .gx_modular5 .gx_title .gx_tle_line,
  .gx_modular6 .gx_title .gx_tle_line {
    width: 841px;
  }

  .gx_modular4 {
    padding-top: 49px;
  }

  .gx_modular4 .gx_intro {
    padding-top: 24px;
  }

  .gx_modular4 .gx_intro img {
    width: 1000px;
    height: 215px;
  }

  .gx_modular4 .gx_intro p {
    font-size: 14px;
    line-height: 30px;
  }

  .gx_modular5 {
    padding: 34px 0 44px;
  }

  .gx_modular5 .picScroll-left {
    margin-top: 26px;
  }

  .gx_modular5 .picScroll-left .hd {
    top: 62px;
  }

  .gx_modular5 .picScroll-left .hd .prev,
  .gx_modular5 .picScroll-left .bd {
    width: 880px;
  }

  .gx_modular5 .picScroll-left .bd .tempWrap {
    width: 880px !important;
  }

  .gx_modular5 .picScroll-left .bd ul li {
    width: 135px !important;
    margin-right: 50px;
  }

  .gx_modular5 .picScroll-left .bd ul li img {
    width: 135px;
    height: 180px;
  }

  .gx_modular5 .picScroll-left .bd ul li h3 {
    font-size: 17px;
    padding: 16px 0 14px;
  }

  .gx_modular5 .picScroll-left .bd ul li p {
    font-size: 14px;
    line-height: 16px;
  }

  .gx_modular6 .gx_structure {
    margin-top: 24px;
    padding: 20px 1px;
  }

  .gx_modular6 {
    padding-bottom: 66px;
  }

  .gx_modular5 .picScroll-left .tempWrap {
    margin: 0 auto;
    height: 265px;
  }

  .gx_modular5 .picScroll-left .bd ul li {
    width: 135px !important;
    margin-right: 50px;
  }

  .gx_modular5 .picScroll-left .tempWrap {
    margin: 0 auto;
    width: 880px !important;
  }

  /*融通频道-关于融通 end */
  /*融通频道-党建 start */
  .gx_ejConBox {
    padding: 32px 0 55px;
  }

  .gx_pub_tle {
    padding: 25px 40px 56px 24px;
  }

  .gx_modular7 {
    padding: 0 23px;
  }

  .gx_left {
    width: 484px;
  }

  .gx_left.gx_ghgz_cont {
    margin-top: 42px;
  }

  .gx_modular7 .slideBox {
    width: 484px;
    height: 292px;
  }

  .gx_modular7 .slideBox .hd {
    height: 7px;
    right: 17px;
    bottom: 18px;
  }

  .gx_modular7 .slideBox .hd ul li {
    margin-left: 6px;
    width: 7px;
    height: 7px;
  }

  .gx_modular7 .slideBox .bd img {
    width: 484px;
    height: 292px;
  }

  .gx_modular7 .slideBox .bd p {
    font-size: 14px;
  }

  .gx_right {
    width: 432px;
  }

  .gx_title_sy .gx_tle_name {
    font-size: 19px;
    padding: 24px 0 0 99px;
  }

  .gx_title_sy .gx_tle_more {
    font-size: 13px;
    margin: 27px 10px 0 0;
    background-size: 10px auto;
    padding-right: 18px;
  }

  .gx_group_list_dj li {
    padding: 13px 0;
  }

  .gx_group_list_dj li + li + li + li + li + li {
    padding-bottom: 16px;
  }

  .gx_right .gx_group_list li a {
    max-width: 330px;
  }

  .gx_pictures {
    width: 432px;
    height: 327px;
    padding-top: 24px;
    margin-top: 0;
  }

  .gx_pictures .gx_imgBox + .gx_imgBox {
    margin-top: 16px;
  }

  .gx_pictures .gx_imgBox,
  .gx_pictures .gx_imgBox img {
    width: 400px;
    height: 142px;
  }

  .gx_pictures .gx_imgBox p {
    width: 357px;
    height: 31px;
    line-height: 31px;
    font-size: 15px;
    padding-left: 8px;
    bottom: 9px;
    margin-left: -182.5px;
  }

  .gx_zg_img img {
    width: 425px;
    height: 178px;
  }

  .gx_modular9 .picScroll-left {
    margin-top: 25px;
  }

  .gx_modular9 .picScroll-left .hd {
    top: 39px;
  }

  .gx_modular9 .picScroll-left .hd .prev,
  .gx_modular9 .picScroll-left .bd {
    width: 879px;
  }

  .gx_modular9 .picScroll-left .bd ul li {
    width: 201px;
    margin-right: 25px;
  }

  .gx_modular9 .picScroll-left .bd ul li img {
    width: 201px;
    height: 133px;
  }
}
/*顶部导航下拉菜单 start */
.gx_rt_header{position: relative;z-index: 200;}
.gx_header_Nav ul li{position: relative;}
.gx_header_Nav ul li .gx_headNav_menu{position: absolute;top:60px;left:-44px;z-index: 100;width: 118px;padding: 13px 26px 15px;
  box-shadow: 0 5px 8px rgba(99,99,99,0.2);text-align: center;display: none;}
.gx_header_Nav ul li .gx_headNav_menu a{display: block;color: #333333;font-size: 16px;border-bottom: 1px dashed #7d89a3;height: 49px;
  line-height: 49px;}
.gx_header_Nav ul li .gx_headNav_menu a:hover{color: #b81c22;font-weight: bold;}
/*顶部导航下拉菜单 end */


</style>

