<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear" id="topClass">
        <!--        <p id="pTitle"></p>-->
        <a href="/"><img style="margin-top: 50px;" src="/static/zzry/top2.0.png" alt=""/></a>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="nav-link"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a class="nav-link" href="/gywm">关于我们</a>
              <div class="gx_headNav_menu">
                <a class="nav-link-a" href="/gywm">单位简介</a>
                <a class="nav-link-a" href="/jgsz">机构设置</a>
                <a class="nav-link-a" href="/zzry">资质荣誉</a>
              </div>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/xsdw">部门介绍</a>
            </li>
            <li class="nav-link">
              <a class="nav-link" href="/allXwzx">新闻资讯</a>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/kjcx">科技创新</a>
<!--              <div class="gx_headNav_menu">-->
<!--                <a class="nav-link-a" href="/kjcx">创新平台</a>-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/allDdzx">设备展示</a>&ndash;&gt;-->
<!--              </div>-->
            </li>

            <li class="rt4">
              <a class="nav-link" href="/allDdzx">党群建设</a>
<!--              <div class="gx_headNav_menu">-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/AllDdjsEducation">主题教育</a>&ndash;&gt;-->
<!--                <a class="nav-link-a" href="/allDdzx">党群建设</a>-->
<!--              </div>-->
            </li>
            <!--            <li class="rt5">-->
            <!--              <a class="nav-link" href="#/fwzc">服务支持</a>-->
            <!--            </li>-->

<!--            <li class="rt6">-->
<!--              <a class="nav-link" style="background-color: #0056a2" href="/ywly">主营业务</a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->

    <!-- content start -->
    <div class="rc_cont">
      <div class="rc_Zcont">
        <div class="rc_crumbs fl">当前位置：<a href="../" target="_self" title="首页" class="CurrChnlCls">首页</a>&nbsp;>&nbsp;<a
            href="/ywly" target="_self" title="主营业务" class="CurrChnlCls">主营业务</a></div>

        <div class="rc_tit2 clear yw_tit">
          <ul>
            <li v-for="item in imagesUrl">
              <p class="by_me">{{ item.name }}</p>
              <a href=""><img style="width: 100%;height: 100%" :src="item.url" alt=""/></a>
              <p class="yw_tit_bif mobileNone"><a href="">{{ item.note }}</a>
              </p>
              <div class="zz mobileNone">
                <img src="../views/images/rt_ywly_titbg_v2020.png"/>
              </div>
<!--              <span class="pcNone"><a href="">查看详情  > ></a></span>-->
            </li>

          </ul>
        </div>

        <div style="clear: both"></div>
      </div>
    </div>
    <!-- content end -->
    <!-- footer start -->
    <div class="rt_footer" style="position: relative">
      <img style="position: absolute;top: -11px;left: 450px" src="../assets/import/友情链接.png"/>
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
<!--            <li class="yqlj"><a href="">友情链接</a></li>-->
            <li><a href="http://scdzdc.cn/" target="_blank">四川省地质调查研究院</a></li>
            <li><a href="https://www.mnr.gov.cn/" target="_blank">自然资源部</a></li>
            <li><a href="https://ngcc.cn/ngcc/" target="_blank">国家基础地理信息中心</a></li>
            <li><a href="http://dnr.sc.gov.cn/" target="_blank">四川省自然资源厅</a></li>
            <li><a href="http://scsm.mnr.gov.cn/" target="_blank">四川省测绘地理信息局</a></li>
            <li><a class="fwzc" href="/fwzc" target="_blank">服务支持</a></li>
          </ul>

        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="/lxwm" target="_blank">网站地图</a>|
              <a href="/lxwm" target="_blank">联系我们</a>|
              <!--              -->
            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省地质调查研究院测绘地理信息中心版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn/?lang=zh-cn#/Integrated/index" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh">川公网安备 51010502010510号 </a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "Home",
  data() {
    return {
      pictureList: [],
      imagesUrl: [
        //http://127.0.0.1:8000/img/W020220612734563063084.1e15b038.jpg
        {
          name: "基础测绘",
          url: require("@/assets/import/3基础测绘2.jpg"),
          note: "卫星定位测量、水准测量、三角测量、大地测量数据处理、控制测量、地形测量、规划测量、建筑工程测量、变形监测、地下管线测量、工程测量监理。"
        },
        {
          name: "工程测量",
          url: require("@/assets/import/6工程测量2.jpg"),
          note: "包括控制测量、地理信息系统工程、不动产测绘、矿山测量、水下测量、竣工测量、地下管线探测等。"
        },
        {name: "不动产测绘", url: require("@/assets/import/5不动产测绘2.jpg"), note: "行政区域界线测绘，地籍测绘、房产测绘等不动产测绘，不动产测绘监理。"},
        {
          name: "自然资源调查与确权",
          url: require("@/assets/import/9自然资源调查与确权2.jpg"),
          note: "自然资源统一确权登记、自然资源专项调查（森林资源调查、水资源调查等）、国土调查、耕地质量分类、耕地调查监测与保护、耕地动态监测、耕地后备资源调查、自然资源资产清查、卫片执法等"
        },

        {
          name: "国土空间规划",
          url: require("@/assets/import/1国土空间规划2.jpg"),
          note: "国土空间总体规划类；乡村国土空间规划、控制性详细规划、城市更新改造、TOD一体化城市设计等详细规划与城市设计类；城乡地名、市政设施、公共服务设施、农业现代化、应急体系、消防、生态修复、人居环境与土地整治等专项规划类；全域土地综合整治、成片土地开发、高标准农田、城乡建设用地增减挂钩等国土整治类；历史文化名城、传统村落、景区概念、产业策划等规划。"
        },
        {
          name: "摄影测量与遥感",
          url: require("@/assets/import/4测绘航空摄影测量与遥感2.jpg"),
          note: "大比例尺测图、地质灾害滑坡监测、矿山测量及监测、高速公路选址勘察设计、三维高精度点云辅助建模、电力巡线、林业普查、地籍测量等；遥感卫星影像处理与应用专业团队，遥感测绘、合成孔径雷达（SAR）影像地质灾害隐患识别与变化监测、高分辨率遥感卫星影像土地利用变化监测、自然资源调查及变化监测、生态环境监测、农作物监测、农业灾害监测、地质灾害体遥感解译及相关专题图制作等。"
        },
        {
          name: "测绘地理信息大数据服务",
          url: require("@/assets/import/7测绘地理信息大数据服务2.jpg"),
          note: "地理信息数据库建设，地理信息集成、开发服务，地理信息系统集成，地理信息系统生产软件开发，地理信息行业应用定制开发，地理信息系统运行维护服务，智能识别，信息化解决方案服务"
        },
        {
          name: "地质灾害防治与应急抢险",
          url: require("@/assets/import/8地质灾害防治与应急抢险2.jpg"),
          note: "地质灾害调查评价、地质灾害隐患排查与勘查设计、地质灾害危险性评估、预警预报、应急调查等；地质灾害群测群防、专业监测和预警预报、应急救援、地质灾害工程治理等。"
        },
        {
          name: "地质矿产勘查",
          url: require("@/assets/import/10地质工程勘察2.jpg"),
          note: "依据先进的地质科学理论，在占有大量野外地质观察和搜集整理有关地质资料的基础上，采用地质测量、物化探、钻坑探工程等综合地质手段和方法，取得可靠的地质矿产信息资料，包括查明矿产资源储量、矿石质量、采选冶加工技术条件、矿床技术经济评价等，为矿业权出让或矿山设计建设提供依据。"
        },
        {
          name: "岩土勘察",
          url: require("@/assets/import/11岩土工程勘察2.jpg"),
          note: "通过开展地质测绘、勘探、室内实验、原位测试等工作，研究、评价建设场地的地形、地貌、地质构造、地层岩性、岩土物理力学性质、不良地质现象以及水文地质条件等工程地质条件，为工程建设的规划、设计、施工提供必要的依据及参数。"
        },
        {name: "地图编制", url: require("@/assets/import/2地图编制2.jpg"), note: "标准地图编制、专题地图编制、规划与旅游等其他地图编制"},
        {name: "档案数字化", url: require("@/assets/import/12档案数字化2.jpg"), note: "档案咨询服务、数字化扫描、缩微加工与专业档案整理等内容。"},
      ],
    };
  },
  methods: {
    onloadIntroduce() {
      var top = $('.yw_tit li').find('div').position();
      $('.yw_tit li').hover(function () {

        $(this).find('div.zz').stop().animate({top: '84px'})
        $(this).find('.yw_tit_bif').stop().animate({top: '84px'})
      }, function () {
        $(this).find('div.zz').stop().animate({top: '280px'})
        $(this).find('.yw_tit_bif').stop().animate({top: '280px'})
      })
    },
    checkForm1() {
      $("#searchword").val($.trim($("#searchword").val()));
      $("#searchword").val($("#searchword").val().replace(/请输入搜索内容/g, ''));
      $("#searchword").val($("#searchword").val().replace(/[\-\_\,\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g, ''));
      $("#searchword").val($("#searchword").val().replace(/script/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/iframe/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/update/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/alert/ig, ''));

      if ($("#searchword").val() == "") {
        alert("请输入搜索内容");
        return false;
      } else {
        return true;
      }
    },
    submit() {
      if (checkForm1()) {
        document.searchform.submit();
      }
    },
    load() {
      axios.get("").then(res => {
        this.pictureList = res.data;
      })
    }
  },
  method: {},
  mounted: function () {
    this.load();
    this.onloadIntroduce();
  },
}
</script>

<style>

.nav-link {
  display: block;
  width: 200px;
  text-align: center;
}


.nav-link:hover {
  background-color: #0056a2 !important;
}

.nav-link-a {
  display: block;
  width: 186px;
}

.nav-link-a:hover {
  background-color: rgba(21, 152, 255, 0.5) !important;
  border-radius: 4px;
}

#topClass {
  background-image: url('@/assets/top.jpg');
  background-size: 100% 100%;
  width: 100%;
  text-align: center;
  display: table;
  height: 224px;
}

#topClass a {
  display: table-cell;
  vertical-align: middle;
}


#pTitle {
  position: absolute;
  top: 20%;
  left: 20%;
}

.fwzc {
  color: #0057fb;
  font-weight: bold;
  font-size: 20px;
}

/** 添加分割线*/
.fwzc:before {
  content: "|";
  float: left;
  margin-right: 20px;
  color: #94959E;
}

.fwzc:after {
  content: "|";
  float: right;
  margin-left: 20px;
  color: #94959E;
}
.by_me{
  height: 88px  !important;
  line-height: 130px !important;
}

</style>
