<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear" id="topClass">
        <!--        <p id="pTitle"></p>-->
        <a href="/"><img style="margin-top: 50px;" src="/static/zzry/top2.0.png" alt=""/></a>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="nav-link"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a class="nav-link" href="/gywm">关于我们</a>
              <div class="gx_headNav_menu">
                <a class="nav-link-a" href="/gywm">单位简介</a>
                <a class="nav-link-a" href="/jgsz">机构设置</a>
                <a class="nav-link-a" href="/zzry">资质荣誉</a>
              </div>
            </li>
            <li class="rt3">
              <a class="nav-link" style="background-color: #0056a2" href="/xsdw">部门介绍</a>
            </li>
            <li class="nav-link">
              <a class="nav-link" href="/allXwzx">新闻资讯</a>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/kjcx">科技创新</a>
<!--              <div class="gx_headNav_menu">-->
<!--                <a class="nav-link-a" href="/kjcx">创新平台</a>-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/allDdzx">设备展示</a>&ndash;&gt;-->
<!--              </div>-->
            </li>

            <li class="rt4">
              <a class="nav-link" href="/allDdzx">党群建设</a>
<!--              <div class="gx_headNav_menu">-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/AllDdjsEducation">主题教育</a>&ndash;&gt;-->
<!--                <a class="nav-link-a" href="/allDdzx">党群建设</a>-->
<!--              </div>-->
            </li>
            <!--            <li class="rt5">-->
            <!--              <a class="nav-link" href="#/fwzc">服务支持</a>-->
            <!--            </li>-->

<!--            <li class="rt6">-->
<!--              <a class="nav-link" href="/ywly">主营业务</a>-->
<!--            </li>-->

          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->
    <!-- content start -->
    <div class="ejAbtrtC ejXwzxxq">
      <div class="ejCon clear">
        <div class="ejL">
          <div class="artT">
            机构设置
          </div>
          <ul>
            <!--            <el-divider>职能部门</el-divider>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='综合办公室' @click="changeDepartment('A1')">-->
            <!--                综合办公室-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='组织人事科' @click="changeDepartment('A2')">-->
            <!--                组织人事科-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='财务资产管理科' @click="changeDepartment('A3')">-->
            <!--                财务资产管理科-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='纪委办公室' @click="changeDepartment('A4')">-->
            <!--                纪委办公室-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='审计科' @click="changeDepartment('A5')">-->
            <!--                审计科-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='经营科' @click="changeDepartment('A6')">-->
            <!--                经营科-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='生产技术科' @click="changeDepartment('A7')">-->
            <!--                生产技术科-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <el-divider>生产部门</el-divider>-->
            <li class="nav-link-B" @click="clickCategory(1)" :class="{actives:categoryIndex==1}">
              <a title='测绘地理信息所' @click="changeDepartment('B1')">
                测绘地理信息所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(2)" :class="{actives:categoryIndex==2}">
              <a title='调查监测评价所' @click="changeDepartment('B2')">
                调查监测评价所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(3)" :class="{actives:categoryIndex==3}">
              <a title='国土工程信息所' @click="changeDepartment('B3')">
                国土工程信息所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(4)" :class="{actives:categoryIndex==4}">
              <a title='国土空间规划所' @click="changeDepartment('B4')">
                国土空间规划所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(5)" :class="{actives:categoryIndex==5}">
              <a title='航测遥感所' @click="changeDepartment('B5')">
                航测遥感所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(6)" :class="{actives:categoryIndex==6}">
              <a title='信息技术所' @click="changeDepartment('B6')">
                信息技术所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(7)" :class="{actives:categoryIndex==7}">
              <a title='国土空间用途管制所' @click="changeDepartment('B7')">
                国土空间用途管制所
              </a>
            </li>
            <li class="nav-link-B" @click="clickCategory(8)" :class="{actives:categoryIndex==8}">
              <a title='益新工程勘察设计有限公司' @click="changeDepartment('B8')">
                {{ this.nameOfYX }}
              </a>
            </li>
            <!--            <el-divider>子公司</el-divider>-->
            <!--            <li class="nav-link-B">-->
            <!--              <a title='测绘地理信息所' @click="changeDepartment('C1')">-->
            <!--                测绘地理信息所-->
            <!--              </a>-->
            <!--            </li>-->
          </ul>
        </div>
        <div class="ejR">
          <div class="ejRt clear">
            <div class="ejRtT">{{ this.pictureList.name }}</div>
            <div class="ejcub">
              <span>当前位置：</span> <a href="./" target="_self" title="首页" class="CurrChnlCls">首页</a>&nbsp;>&nbsp;<a
                href="./gywm" target="_self" title="关于我们" class="CurrChnlCls">关于我们</a>&nbsp;>&nbsp;<a href="/xsdw"
                                                                                                      target="_self"
                                                                                                      title="机构设置"
                                                                                                      class="CurrChnlCls">机构设置</a>
            </div>
          </div>
          <div class="ejRb clear">
            <div class="ejartD">
              <div class="video">
              </div>
              <p>
                <span style="white-space: pre-wrap">
                  {{ this.pictureList.articleContent }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content end -->

    <!-- footer start -->
    <div class="rt_footer" style="position: relative">
      <img style="position: absolute;top: -11px;left: 450px" src="../assets/import/友情链接.png"/>
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
<!--            <li class="yqlj"><a href="">友情链接</a></li>-->
            <li><a href="http://scdzdc.cn/" target="_blank">四川省地质调查研究院</a></li>
            <li><a href="https://www.mnr.gov.cn/" target="_blank">自然资源部</a></li>
            <li><a href="https://ngcc.cn/ngcc/" target="_blank">国家基础地理信息中心</a></li>
            <li><a href="http://dnr.sc.gov.cn/" target="_blank">四川省自然资源厅</a></li>
            <li><a href="http://scsm.mnr.gov.cn/" target="_blank">四川省测绘地理信息局</a></li>
            <li><a class="fwzc" href="/fwzc" target="_blank">服务支持</a></li>
          </ul>

        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="/lxwm" target="_blank">网站地图</a>|
              <a href="/lxwm" target="_blank">联系我们</a>|
              <!--              -->
            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省地质调查研究院测绘地理信息中心版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn/?lang=zh-cn#/Integrated/index" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh">川公网安备 51010502010510号 </a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
// import axios from "axios";

export default {
  components: {},
  name: "XwzxForView",
  data() {
    return {
      categoryIndex: 0,
      pictureList: {
        name: "",
        articleContent: null
      },
      nameOfYX: '益新工程勘察\n\xa0\xa0设计有限公司',
      type: "dwjj"
    };
  },
  methods: {
    clickCategory(index) { // 这里我们传入一个当前值
      this.categoryIndex = index
    },
    checkForm1() {
      $("#searchword").val($.trim($("#searchword").val()));
      $("#searchword").val($("#searchword").val().replace(/请输入搜索内容/g, ''));
      $("#searchword").val($("#searchword").val().replace(/[\-\_\,\!\|\~\`\(\)\#\$\%\^\&\*\{\}\:\;\"\L\<\>\?]/g, ''));
      $("#searchword").val($("#searchword").val().replace(/script/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/iframe/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/update/ig, ''));
      $("#searchword").val($("#searchword").val().replace(/alert/ig, ''));

      if ($("#searchword").val() == "") {
        alert("请输入搜索内容");
        return false;
      } else {
        return true;
      }
    },
    submit() {
      if (checkForm1()) {
        document.searchform.submit();
      }
    },
    // load() {
    //   this.axios.get("/about/getAb", {params: {type: this.type}}).then(res => {
    //     console.log(res.data.data.articleContent)
    //     this.pictureList = res.data.data;
    //   })
    // },
    changeDepartment(id) {
      if (id == 'B1') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0承担基础性测绘地理信息保障工作及相关科学研究。提供大地测量、地形测量、规划测量、线路测量、综合管线调查、不动产测绘、多测合一等测绘业务。提供国土、交通、农业、水利、公安、民政等地理信息数据采集及处理服务。"
        this.pictureList.name = "测绘地理信息所"
      } else if (id == 'B2') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0负责调查监测评价工作的技术牵头。承担自然资源调查及其他专项调查、日常监测及分析评价等技术服务和科学研究。参与自然资源确权登记、自然资源保护、自然资源执法、勘测定界等技术服务。"
        this.pictureList.name = "调查监测评价所"
      } else if (id == 'B3') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0承担国土工程、耕地保护与利用方面的测绘地理信息技术服务和科学研究。开展耕地保护调查监测与评价、国土调查、土地开发利用、测绘工程监理等技术服务。"
        this.pictureList.name = "国土工程信息所"
      } else if (id == 'B4') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0承担城市区域发展、国土空间规划、土地利用评价技术服务和科学研究。开展国土空间总体规划、专项规划、详细规划、城市设计、城市更新、乡村振兴、国土综合整治、自然资源调查等技术服务。开展建设项目用地踏勘论证、节地评价、规划选址论证等自然资源要素保障咨询评估工作。"
        this.pictureList.name = "国土空间规划所"
      } else if (id == 'B5') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0承担摄影测量与遥感、测绘航空摄影、地理信息系统工程、地图编制、实景三维建设等工作。提供应急测绘保障、测绘地理信息数据加工及大数据运算服务。"
        this.pictureList.name = "航测遥感所"
      } else if (id == 'B6') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0承担各类测绘地理信息系统研发、推广应用和数据治理等信息技术服务。负责测绘地理信息相关的科技研发和技术创新工作，开展智能化测绘和时空大数据等新型技术的研究和应用。"
        this.pictureList.name = "信息技术所"
      } else if (id == 'B7') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0开展设施农用地备案、农村村民住宅用地农用地转用、集体建设用地农用地转用、建设项目规划选址和用地预审、建设用地报批、临时用地报批等用地要素保障相关资料编制、组卷等自然资源要素保障咨询评估工作。"
        this.pictureList.name = "国土空间用途管制所"
      } else if (id == 'B8') {
        this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0四川益新工程勘察设计有限公司成立于2010年01月21日，位于成都市金牛区，是四川省地质调查研究院测绘地理信息中心（原四川省煤田测绘工程院）独资、具有独立法人的国有企业、国家高新技术企业，下设一处攀西分公司分支机构。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0益新公司自成立以来始终坚持可持续发展理念，以经营为龙头，市场为导向，不断拓展业务能力范围，提高市场竞争力和综合实力，现有甲级测绘资质：摄影测量与遥感、工程测量、界线与不动产测绘、地理信息系统工程；乙级测绘资质：测绘航空摄影、地图编制；地质灾害治理工程勘查甲级资质；地质灾害治理工程设计甲级资质；地质灾害危险性评估乙级资质；地质灾害治理工程监理丙级资质，地质勘查资质：区域地质调查乙级，固体矿产勘查乙级，水文地质、工程地质、环境地质调查乙级；土地规划乙级资质。承担的多个项目被中国测绘学会、中国地理信息产业协会、四川省测绘地理信息学会和四川省地理信息产业协会评为优秀工程；多次被评为“中国地理信息产业最具活力中小企业”“地理信息产业最具成长性企业”称号。\n" +
            "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0益新公司各项工作围绕经营业务开展，以经济建设为中心，大力拓展三大板块，即建设工程勘察、测绘服务、建设工程监理；同时带动一般业务领域发展，涵盖地理遥感信息服务、地质勘查技术服务、地质灾害治理服务、环保咨询服务、生态资源监测、工程管理服务、规划设计管理、水资源管理、水文服务、水土流失防治服务、水污染治理、土壤污染治理与修复服务、生态恢复及生态保护服务、环境应急治理服务、土地整治服务、土地调查评估服务、不动产登记代理服务、互联网数据服务、软件开发、计算机软硬件及辅助设备零售、社会稳定风险评估。主持生产、研究的项目获国家级奖项6个、省级奖项9个。\n" +
            "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0益新公司坚持实施人才强企战略、夯实企业发展根基，持续推进产学研深度融合，培育技能人才，不断提升技术创新水平，促进企业高质量发展。现有职工102人，其中高、中级工程师21名，助理工程师28人，本科及以上学历占职工总人数55.8％。\n" +
            "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0益新公司将继续秉承“责任 高效 融合 卓越”的理念，持续聚焦绿色发展，努力开辟发展新赛道，不断加强专业建设，创新发展理念，完善管理体系，优质服务支撑自然资源管理，立志发展成为一家集测绘地理信息、自然资源管理、地质勘查与地质灾害治理工程勘查、设计为一体的科技服务型企业。"
        this.pictureList.name = "四川益新工程勘察设计有限公司"
      }

    },
  },
  mounted() {
    this.pictureList.articleContent = "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0承担基础性测绘地理信息保障工作及相关科学研究。提供大地测量、地形测量、规划测量、线路测量、综合管线调查、不动产测绘、多测合一等测绘业务。提供国土、交通、农业、水利、公安、民政等地理信息数据采集及处理服务。"
    this.pictureList.name = "测绘地理信息所"
  },
  created() {
  }
}
</script>

<style>
.video {
  width: 80%;
  height: auto;
  margin: 0 auto;

}

.video .video_box {
  width: 100%;
  height: auto;
  background: #000;
}

.nav-link {
  display: block;
  width: 200px;
  text-align: center;
}


.nav-link:hover {
  background-color: #0056a2 !important;
}

.nav-link-a {
  display: block;
  width: 186px;
}

.nav-link-a:hover {
  background-color: rgba(21, 152, 255, 0.5) !important;
  border-radius: 4px;
}

.actives {
  background-color: #dddddd !important;
  font-weight: bolder;
}


.nav-link-B a {
  font-size: 13px !important;
  font-family: 微软雅黑;
  white-space: pre-wrap;
}

.nav-link-B a:focus {
  color: #0d50e0;
  /*background: url("./images/on.png");*/
  background-size: 100%, 100%;
}


#topClass {
  background-image: url('@/assets/top.jpg');
  background-size: 100% 100%;
  width: 100%;
  text-align: center;
  display: table;
  height: 224px;
}

#topClass a {
  display: table-cell;
  vertical-align: middle;
}

.fwzc {
  color: #0057fb !important;
  font-weight: bold;
  font-size: 20px;
}

/** 添加分割线*/
.fwzc:before {
  content: "|";
  float: left;
  margin-right: 20px;
  color: #94959E;
}

.fwzc:after {
  content: "|";
  float: right;
  margin-left: 20px;
  color: #94959E;
}
</style>
