import { render, staticRenderFns } from "./Allflfg.vue?vue&type=template&id=3286eb39&scoped=true&"
import script from "./Allflfg.vue?vue&type=script&lang=js&"
export * from "./Allflfg.vue?vue&type=script&lang=js&"
import style0 from "./Allflfg.vue?vue&type=style&index=0&id=3286eb39&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3286eb39",
  null
  
)

export default component.exports