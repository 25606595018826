<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear" id="topClass">
        <!--        <p id="pTitle"></p>-->
        <a href="/"><img style="margin-top: 50px;" src="/static/zzry/top2.0.png" alt=""/></a>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="nav-link"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a class="nav-link" href="/gywm">关于我们</a>
              <div class="gx_headNav_menu">
                <a class="nav-link-a" href="/gywm">单位简介</a>
                <a class="nav-link-a" href="/jgsz">机构设置</a>
                <a class="nav-link-a" href="/zzry">资质荣誉</a>
              </div>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/xsdw">部门介绍</a>
            </li>
            <li class="nav-link">
              <a class="nav-link" href="/allXwzx">新闻资讯</a>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/kjcx">科技创新</a>
<!--              <div class="gx_headNav_menu">-->
<!--                <a class="nav-link-a" href="/kjcx">创新平台</a>-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/allDdzx">设备展示</a>&ndash;&gt;-->
<!--              </div>-->
            </li>

            <li class="rt4">
              <a class="nav-link" href="/allDdzx">党群建设</a>
<!--              <div class="gx_headNav_menu">-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/AllDdjsEducation">主题教育</a>&ndash;&gt;-->
<!--                <a class="nav-link-a" href="/allDdzx">党群建设</a>-->
<!--              </div>-->
            </li>
            <!--            <li class="rt5">-->
            <!--              <a class="nav-link" href="#/fwzc">服务支持</a>-->
            <!--            </li>-->

<!--            <li class="rt6">-->
<!--              <a class="nav-link" href="/ywly">主营业务</a>-->
<!--            </li>-->

          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->

    <div class="ejAbtrtC ejXwzxxq">
      <div class="ejCon clear">
        <div class="ejL">
          <div class="artT">
            服务支持
          </div>
          <ul>
            <li class="on">
              <a title='服务支持' href="../">
                服务体系
              </a>
            </li>
          </ul>
        </div>
        <div class="ejR">
          <div class="ejRt clear">
            <div class="ejRtT">服务体系</div>
            <div class="ejcub">
              <span>当前位置：</span> <a href="../../../" target="_self" title="首页" class="CurrChnlCls">首页</a>&nbsp;>&nbsp;<a
                href="../../" target="_self" title="新闻中心" class="CurrChnlCls">服务支持</a>&nbsp;>&nbsp;<a href="../"
                                                                                                      target="_self"
                                                                                                      title="服务体系"
                                                                                                      class="CurrChnlCls">服务体系</a>
            </div>
          </div>
          <div class="ejRb clear">
            <div class="title">
              四川省地质调查研究院测绘地理信息中心
            </div>
<!--            <div class="infos clear">-->
<!--              <span class="source">服务理念：以市场为导向，经营为龙头，管理为核心，技术为保障，创新为手段，效益为目的。</span>-->
<!--            </div>-->
            <div class="ejartD">
              <div class="video">
                <video style="display:none" class="video_box" id="video-player-html5" preload="" autoplay
                       controls>
                  <source src="" type="video/mp4">
                  <a href="" id="player"></a>
                </video>
              </div>
              <div class="view TRS_UEDITOR trs_paper_default trs_external"><p>
                　　四川省地质调查研究院测绘地理信息中心积极服务自然资源管理、生态环境保护、国土空间规划、地质灾害勘察设计等领域。测绘中心有专门的售后服务部门，该售后部门是一支经验丰富的工程师队伍，能够保证为用户提供全面、及时、完善的售后服务，免除用户的后顾之忧。</p>
                <p>（1）电话服务（028-87333021）</p>
                <p>
                  　　服务人员不到现场，通过电话方式为业主提供服务，指导客户相关人员进行相应操作以完成有关服务内容，确保业主的需求能得到及时准确的反馈。</p>
                <p>（2）定期回访服务</p>
                <p>
                  　　对用户提供定期的现场回访、电话回访和E-mail回访支持。</p>
                <p>（3）远程维护</p>
                <p>
                  　　对可以通过拨号等网络接入方式直接进行远程操控解决的问题，我单位专业技术人员可以直接对业主的问题进行分析，并提供售后服务。</p>
                <p>（4）电子邮件服务（E mail：sddychzx@163.com）</p>
                <p>
                  　　客户的技术或非技术问题及建议可以通过电子邮件方式发送给我单位的售后服务电子邮箱，我单位设立专人阅读并及时给予答复。</p>
                <p>（5）现场售后服务</p>
                <p>
                  　　针对比较复杂的问题，我单位的专业技术人员可以来到现场，通过仔细的调查研究，为客户解决实质问题。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer start -->
    <div class="rt_footer" style="position: relative">
      <img style="position: absolute;top: -11px;left: 450px" src="../assets/import/友情链接.png"/>
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
<!--            <li class="yqlj"><a href="">友情链接</a></li>-->
            <li><a href="http://scdzdc.cn/" target="_blank">四川省地质调查研究院</a></li>
            <li><a href="https://www.mnr.gov.cn/" target="_blank">自然资源部</a></li>
            <li><a href="https://ngcc.cn/ngcc/" target="_blank">国家基础地理信息中心</a></li>
            <li><a href="http://dnr.sc.gov.cn/" target="_blank">四川省自然资源厅</a></li>
            <li><a href="http://scsm.mnr.gov.cn/" target="_blank">四川省测绘地理信息局</a></li>
            <li><a class="fwzc" href="/fwzc" target="_blank">服务支持</a></li>
          </ul>

        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="/lxwm" target="_blank">网站地图</a>|
              <a href="/lxwm" target="_blank">联系我们</a>|
              <!--              -->
            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省地质调查研究院测绘地理信息中心版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn/?lang=zh-cn#/Integrated/index" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh">川公网安备 51010502010510号 </a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>

<script>
export default {
  name: "FwzcView"
}
</script>

<style scoped>
.nav-link {
  display: block;
  width: 200px;
  text-align: center;
}


.nav-link:hover {
  background-color: #0056a2 !important;
}

.nav-link-a {
  display: block;
  width: 186px;
}

.nav-link-a:hover {
  background-color: rgba(21, 152, 255, 0.5) !important;
  border-radius: 4px;
}

#topClass {
  background-image: url('@/assets/top.jpg');
  background-size: 100% 100%;
  width: 100%;
  text-align: center;
  display: table;
  height: 224px;
}

#topClass a {
  display: table-cell;
  vertical-align: middle;
}

.fwzc {
  color: #0057fb !important;
  font-weight: bold;
  font-size: 20px;
}

/** 添加分割线*/
.fwzc:before {
  content: "|";
  float: left;
  margin-right: 20px;
  color: #94959E;
}

.fwzc:after {
  content: "|";
  float: right;
  margin-left: 20px;
  color: #94959E;
}
</style>
