<template>
  <div id="home">
    <!-- header start -->
    <div class="rt_header gx_rt_header">
      <div class="header_top clear" id="topClass">
        <!--        <p id="pTitle"></p>-->
        <a href="/"><img style="margin-top: 50px;" src="/static/zzry/top2.0.png" alt=""/></a>
      </div>
      <div class="header_Nav gx_header_Nav mobileNone redBg">
        <div>
          <ul>
            <li class="nav-link"><a href="/">网站首页</a></li>
            <li class="rt1">
              <a class="nav-link" href="/gywm">关于我们</a>
              <div class="gx_headNav_menu">
                <a class="nav-link-a" href="/gywm">单位简介</a>
                <a class="nav-link-a" href="/jgsz">机构设置</a>
                <a class="nav-link-a" href="/zzry">资质荣誉</a>
              </div>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/xsdw">部门介绍</a>
            </li>
            <li class="nav-link">
              <a class="nav-link" href="/allXwzx">新闻资讯</a>
            </li>
            <li class="rt3">
              <a class="nav-link" href="/kjcx">科技创新</a>
<!--              <div class="gx_headNav_menu">-->
<!--                <a class="nav-link-a" href="/kjcx">创新平台</a>-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/allDdzx">设备展示</a>&ndash;&gt;-->
<!--              </div>-->
            </li>

            <li class="rt4">
              <a class="nav-link" href="/allDdzx">党群建设</a>
<!--              <div class="gx_headNav_menu">-->
<!--                &lt;!&ndash;                <a class="nav-link-a" href="/AllDdjsEducation">主题教育</a>&ndash;&gt;-->
<!--                <a class="nav-link-a" href="/allDdzx">党群建设</a>-->
<!--              </div>-->
            </li>
            <!--            <li class="rt5">-->
            <!--              <a class="nav-link" href="#/fwzc">服务支持</a>-->
            <!--            </li>-->

<!--            <li class="rt6">-->
<!--              <a class="nav-link" href="/ywly">主营业务</a>-->
<!--            </li>-->

          </ul>
        </div>
      </div>
    </div>
    <!-- header end -->

    <div>
      <el-input v-model="addressKeyword" placeholder="请输入地址"></el-input>
      <!-- 给地图加点击事件getLocationPoint，点击地图获取位置相关的信息 -->
      <!-- scroll-wheel-zoom：是否可以用鼠标滚轮控制地图缩放-->
      <baidu-map
          :scroll-wheel-zoom="true"
          :center="location"
          :zoom="zoom"
          @click="getLocationPoint"
          ak="eVNYZEBKI97NtTmx2yUTD5BMoHi0mzLR"
      >
        > <!-- 一定要设置高度，不然默认会显示0px -->
        <bm-view style="width: 100%; height:620px; flex: 1"></bm-view>
        <bm-marker :position="{lng: 104.030563, lat: 30.6691}" name="四川省地质调查研究院">
          <bm-label content="四川省地质调查研究院"
                    :labelStyle="labelStyle"
                    :offset="{width: -10, height: 30}"/>
        </bm-marker>
        <!-- 用来查询对应的位置 -->
        <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
      </baidu-map>
    </div>
    <div class="footer_cont clear">
      <ul class="footer_cont footer_yqlj">
        <li><a href="">联系地址:成都市青华路38号</a></li>
        <li><a href="">联系电话:028-87342210</a></li>
        <li><a href="">联系邮箱:sddychzx@163.com</a></li>
        <li><a href="">传真:028-87342210</a></li>
      </ul>
    </div>

    <!-- footer start -->
    <div class="rt_footer" style="position: relative">
      <img style="position: absolute;top: -11px;left: 450px" src="../assets/import/友情链接.png"/>
      <div class="rt_footer_center mobileNone">
        <div class="footer_cont clear">
          <ul class="footer_cont footer_yqlj">
<!--            <li class="yqlj"><a href="">友情链接</a></li>-->
            <li><a href="http://scdzdc.cn/" target="_blank">四川省地质调查研究院</a></li>
            <li><a href="https://www.mnr.gov.cn/" target="_blank">自然资源部</a></li>
            <li><a href="https://ngcc.cn/ngcc/" target="_blank">国家基础地理信息中心</a></li>
            <li><a href="http://dnr.sc.gov.cn/" target="_blank">四川省自然资源厅</a></li>
            <li><a href="http://scsm.mnr.gov.cn/" target="_blank">四川省测绘地理信息局</a></li>
            <li><a class="fwzc" href="/fwzc" target="_blank">服务支持</a></li>
          </ul>

        </div>
      </div>
      <div class="rt_footer_bot">
        <div class="footer_cont">
          <div class="footer_botgsMes fl">
            <p class="gsMes">
              <a href="/lxwm" target="_blank">网站地图</a>|
              <a href="/lxwm" target="_blank">联系我们</a>|
              <!--              -->
            </p>
            <p class="mobileNone">
              <span>地址：成都市青华路38号</span>
              <span style="display:none">邮编：100000 </span>
              <span style="display:none">电话：010-80000000 </span>
            </p>
            <p class="mobileNone">
              <span>版权所有：四川省地质调查研究院测绘地理信息中心版权所有</span>

            </p>
            <p class="mobileNone">
              <a href="https://beian.miit.gov.cn/?lang=zh-cn#/Integrated/index" target="_blank">蜀ICP备2023023168号</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"
                 class="jh">川公网安备 51010502010510号 </a>

            </p>

          </div>
          <div class="footer_bot_xmt fl">
          </div>
          <div class="footer_bot_ewm fr">
            <p>官方微信</p>
            <img src="../assets/公众号二维码.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <!-- footer end -->
  </div>
</template>


<script>
import axios from "axios";
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'

export default {
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch
  },
  name: "LxwmView",
  data() {
    return {
      location: {
        lng: 104.030563,
        lat: 30.6691
      },
      labelStyle: {color: 'red', fontSize: '14px', fontWeight: '600'},
      zoom: 20,
      addressKeyword: "",
    };
  },
  methods: {
    getLocationPoint(e) {
      //  点击之后的经纬度
      this.lng = e.point.lng;
      this.lat = e.point.lat;
      // 创建地址解析器的实例
      let geoCoder = new BMap.Geocoder();
      //获取位置对应的坐标
      geoCoder.getPoint(this.addressKeyword, point => {
        this.selectedLng = point.lng;
        this.selectedLat = point.lat;
      });
      //利用坐标获取地址的详细信息
      geoCoder.getLocation(e.point, res => {
        console.log(res);
      })
    },
  },
  method: {},
  mounted: function () {

  }
}
</script>

<style scoped>

</style>
